<div class="mandate-table-container">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>EMI DUE DATE</th>
      <td mat-cell *matCellDef="let user">
        {{ user.dueDate }}
      </td>
    </ng-container>

    <ng-container matColumnDef="executionDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        EMI COLLECTION DATE
      </th>
      <td mat-cell *matCellDef="let user">{{ user.executionDate | date : "dd-MM-yyyy"  }}</td>
    </ng-container>

    <ng-container matColumnDef="siTransactionId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>TRANSACTION ID</th>
      <td mat-cell *matCellDef="let user">{{ user.siTransactionId }}</td>
    </ng-container>

    <ng-container matColumnDef="failureReason">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>REASON</th>
      <td mat-cell *matCellDef="let user">
        {{ user.failureReason }}
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
      <td mat-cell *matCellDef="let user" [class]="user.status">
        {{ statusViewMapper[user?.status] }}
      </td>
    </ng-container>
    <ng-container matColumnDef="noOfRetryLeft">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        NO OF RETRIES LEFT
      </th>
      <td mat-cell *matCellDef="let user">
        {{ user.noOfRetryLeft }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      class="row-even"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="resultsLength !== 0"
    [pageSize]="pageSize"
    [length]="resultsLength"
    [showFirstLastButtons]="true"
    [pageIndex]="currentPage"
    (page)="onPageChange($event)"
    [showPageSizeOptions]="false"
  ></mat-paginator>
</div>

<ng-container *ngIf="resultsLength === 0">
  <div class="no-downloads">
    <mat-icon>save_alt</mat-icon>
    No Transactions
  </div>
</ng-container>
