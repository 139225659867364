<mat-checkbox
  class="mat-option"
  [indeterminate]="isIndeterminate()"
  [checked]="isChecked()"
  (click)="$event.stopPropagation()"
  (change)="toggleSelection($event)"
  (keypress)="$event.stopPropagation()"
  tabindex="0"
>
  {{ text }}
</mat-checkbox>
