<div style="height: 200px; overflow-y: scroll;">
    <form name="additionalData" novalidate #editForm="ngForm">
        <div class="card-body">
            <div class="card-title">
                <div class="col-12 row">
                    <div class="col-10"></div>
                    <div class="col-2" [hidden]="!isEditing">
                        <button [disabled]="disableEdit" (click)="edit()" type="button" class="btn btn-primary">
                            <span jhiTranslate="entity.action.edit">Edit</span>
                        </button>
                    </div>
                    <div class="col-2" [hidden]="!isEditing">
                        <button (click)="cancel()" type="button" class="btn btn-warning">
                            <span jhiTranslate="entity.action.cancel">Cancel</span>
                        </button>
                        <button (click)="save()" type="button" class="btn btn-primary">
                            <span jhiTranslate="entity.action.save">Save</span>
                        </button>
                    </div>
                </div>
                <div class="row row-spacing">
                    <div *ngFor="let addionalProperty of addionalDataMap; index as i">
                        <div class="form-group col-md-3">
                            <ig-input [inputField]="editForm.controls.land_holding"
                                [inputErrors]="editForm.controls.land_holding?.errors">
                                <label class="form-control-label"
                                    for="field_land_holding">{{addionalProperty.propertyName}}</label>
                                <input [hidden]="!isEditing" [disabled]="!isEditing" type="text" class="form-control"
                                    name="landHolding" id="field_land_holding"
                                    [(ngModel)]="addionalProperty.propertyValue" />
                                <div [hidden]="isEditing">
                                    <jhi-null-replace [value]="addionalProperty.propertyValue"></jhi-null-replace>
                                </div>
                            </ig-input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>