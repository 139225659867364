<form [formGroup]="form" (ngSubmit)="onSubmit(true)">
  <div class="flex-content search-container">
    <span class="fa fa-search search-icon"></span>
    <div class="editor-tag" (click)="focusTagInput()" (keypress)="focusTagInput()">
      <span class="tag-item" *ngFor="let tag of tags">
        {{ tag }} <span class="remove-tag" (click)="removeTag(tag)"(keypress)="removeTag(tag)">×</span>
      </span>
      <input
        placeholder="{{ placeholder }}"
        #tagInput
        type="text"
        class="input-tag search-text"
        (keyup)="onKeyUp($event)"
        formControlName="tag"
      />
    </div>
    <div class="search-button-container">
      <button
        type="button"
        *ngIf="tags?.length"
        class="fa fa-times button-close"
        (click)="removeAllTag()"
        title="clear all"
      ></button>
      <button class="button search-button" type="submit">Search</button>
    </div>
  </div>
</form>
