<div class="main-container">
  <div fxLayout="row wrap" style="justify-content: space-between">
    <div>
      <h1>Customer Loans</h1>
      <h5>here are all your customer loans</h5>
    </div>
    <custom-datepicker
      (datesEventEmitter)="getDateRange($event)"
    ></custom-datepicker>
  </div>
  <mat-chip-list>
    <mat-chip
      *ngFor="let chip of chipList"
      [selectable]="true"
      [removable]="true"
      (removed)="remove(chip)"
      class="chip"
    >
      <span class="chip-count">{{ chip.count }}</span> {{ chip.name }}
      <mat-icon matChipRemove *ngIf="true">cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
  <div>
    <table
      mat-table
      [dataSource]="data"
      matSort
      (matSortChange)="sortData()"
      id="listtable"
    >
    <caption> </caption>
      <!-- Date Column -->
      <ng-container matColumnDef="cs.createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.createdDate | date: "dd MMM yyyy" }}</h4>
          <h5>{{ row.createdDate | date: "mediumTime" }}</h5>
        </td>
      </ng-container>

      <!-- Customer Column -->
      <ng-container matColumnDef="c.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CUSTOMER INFO</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.customerName }}</h4>
          <h5>{{ row.customerId }}</h5>
        </td>
      </ng-container>

      <!-- Agent Column -->
      <ng-container matColumnDef="agent">
        <th mat-header-cell *matHeaderCellDef>AGENT DETAILS</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.agentName }}</h4>
          <h5>{{ row.agentId }}</h5>
        </td>
      </ng-container>

      <!-- Product Column -->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>PRODUCT INFO</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.accountType }}</h4>
          <h5>{{ row.accountNumber }}</h5>
          <div
            class="success"
            *ngIf="
              row.accountStatus == 'Approved' || row.accountStatus == 'Active'
            "
          >
            {{ row.accountStatus }}
          </div>
          <div class="failure" *ngIf="row.accountStatus == 'Rejected'">
            {{ row.accountStatus }}
          </div>
          <div
            class="other"
            *ngIf="
              row.accountStatus != 'Approved' &&
              row.accountStatus != 'Active' &&
              row.accountStatus != 'Rejected'
            "
          >
            {{ row.accountStatus }}
          </div>
        </td>
      </ng-container>

      <!-- Branch Column -->
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef>
          BRANCH & CENTER
          <button
            mat-icon-button
            (click)="isOpen = !isOpen"
            type="button"
            cdkOverlayOrigin
            #trigger="cdkOverlayOrigin"
          >
            <mat-icon>arrow_drop_down</mat-icon>
          </button>
          <ng-template
            cdkConnectedOverlay
            [cdkConnectedOverlayOrigin]="trigger"
            [cdkConnectedOverlayOpen]="isOpen"
          >
            <div fxLayout="row wrap" id="filterpanel">
              <ng-multiselect-dropdown
                [placeholder]="'Branch'"
                [settings]="dropdownSettings"
                [data]="branchDropdownList"
                [(ngModel)]="branchSelectedItems"
                (onSelect)="onBranchItemSelect($event)"
                (onSelectAll)="onBranchSelectAll($event)"
                class="filter"
              >
              </ng-multiselect-dropdown>
              <ng-multiselect-dropdown
                [placeholder]="'Center'"
                [settings]="dropdownSettings2"
                [data]="centerDropdownList"
                [(ngModel)]="centerSelectedItems"
                (onSelect)="onCenterItemSelect($event)"
                (onSelectAll)="onCenterSelectAll($event)"
                class="filter"
              >
              </ng-multiselect-dropdown>
              <button mat-button class="btn-cancel" (click)="cancelFilter()">
                Cancel
              </button>
              <button mat-button class="btn-apply" (click)="applyFilter()">
                Apply
              </button>
              <a (click)="clear()" class="btn-clear">Clear</a>
            </div>
          </ng-template>
        </th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.branch }}</h4>
          <h5>{{ row.center }}-{{ row.centerCode }}</h5>
        </td>
      </ng-container>

      <!-- Payment Column -->
      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef>PAYMENT INFO</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.paymentMode }}</h4>
          <h5>{{ row.paymentModeId }}</h5>
          <div class="success" *ngIf="row.paymentModeStatus == 'Success'">
            {{ row.paymentModeStatus }}
          </div>
          <div class="failure" *ngIf="row.paymentModeStatus == 'Failure'">
            {{ row.paymentModeStatus }}
          </div>
          <div
            class="other"
            *ngIf="
              row.paymentModeStatus != 'Success' &&
              row.paymentModeStatus != 'Failure' &&
              row.paymentModeStatus != null
            "
          >
            {{ row.paymentModeStatus }}
          </div>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>ACTION</th>
        <td mat-cell *matCellDef="let row">
          <button
            mat-button
            class="button4"
            [routerLink]="['../customerDetail/' + row.customerId]"
          >
            View
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <mat-paginator
    [pageSize]="pageSize"
    [length]="resultsLength"
    [showFirstLastButtons]="true"
    [pageIndex]="currentPage"
    (page)="handlePage($event)"
  ></mat-paginator>
</div>
