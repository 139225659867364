<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="section-title-text" id="businessKycDetails">
          KYC details
        </div></mat-panel-title
      >
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <app-national-id-details></app-national-id-details>
    <app-business-kra></app-business-kra>
    <app-business-owner-kra></app-business-owner-kra>
  </mat-expansion-panel>
</mat-accordion>
