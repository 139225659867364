export const LOAN_TENURE_UNIT = [
  { label: "Month", value: "MONTH" },
  { label: "Week", value: "WEEK" },
  { label: "FortNight", value: "FORTNIGHT" },
  { label: "Bi-Week", value: "BI_WEEK" },
  { label: "Quarter", value: "QUARTER" },
  { label: "Annual", value: "ANNUAL" },
  { label: "Semi Annual", value: "SEMI_ANNUAL" },
  { label: "Day", value: "DAY" },
];
