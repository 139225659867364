<mat-form-field appearance="outline">
    <mat-label>{{ label }}</mat-label>
    <input 
      matInput 
      [id]="label"
      type="text"
      [(ngModel)]="dataObj.value" 
      [disabled]="!editable"
      [required]="isRequired"
      (input)="OnInput($event)"
    />
</mat-form-field>
  