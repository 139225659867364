<div class="user-change-password-container">
    <div class="headers-container">
        <span class="profile-header">
            Change Password
        </span>
        <span class="profile-sub-header">
            Password expires after 90 days
        </span>
    </div>
    <div class="password-input-container">
        <app-password-input class="current-password-input" [id]="'password'" [showError]="showError"
            [errorMessage]="'Your current password is incorrect'" [myForm]="myForm" [title]="'Current Password'"
            [placeHolder]="'Enter Current Password'" [showGuidelines]="false"></app-password-input>
        <div class="new-password-input-container">
            <app-password-input class="new-password-input" [id]="'newPassword'" [myForm]="myForm"
                [title]="'New Password'" [placeHolder]="'Enter New Password'" [toolTipValid]="true"
                [showGuidelines]="true" [remainingRequirements]="remainingRequirements"
                [showRequirements]="true"></app-password-input>
            <app-password-input class="new-password-input" [id]="'confirmPassword'"
                [showError]="myForm?.errors?.['passwordMismatch']" [errorMessage]="'Passwords do not match'"
                [myForm]="myForm" [title]="'Confirm New Password'" [placeHolder]="'Enter New Password'"
                [toolTipValid]="true" [showGuidelines]="true"></app-password-input>
        </div>
    </div>
    <div class="buttons-container">
        <div class="submit-button">
            <app-login-page-button [disabled]="myForm?.invalid" [padding]="'1.5vh'" [title]="'Save Changes'"
                (onButtonClick)="changePassword()"></app-login-page-button>
        </div>
        <button class="clear-all-button" (click)="clearInput()"> Clear All </button>
    </div>
</div>