<div class="col-lg-11 mandate-container">
  <div class="row">
    <div class="topbar-container">
      <div class="back-button-container" *ngIf="mandateInfo">
        <div class="back-button" [routerLink]="['/paymentMandate']">
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M20 9.5H3.5M9 2L1.5 9.5L9 17"
              stroke="#999999"
              stroke-width="3"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        &nbsp; &nbsp; &nbsp;
        <h2>{{ mandateInfo.customerName }}</h2>
      </div>
      <div class="back-button-container">
        <button
          mat-raised-button
          [hidden]="!hideCloseMandate"
          class="button closed-mandate mouse-pointer"
          (click)="downloadTransactions()"
        >
          Mandate closed
        </button>
        <button
          mat-raised-button
          [hidden]="hideCloseMandate"
          class="button2 download-button mouse-pointer"
          (click)="downloadTransactions()"
        >
          Download
        </button>
        &nbsp; &nbsp; &nbsp;
        <button
          mat-raised-button
          [hidden]="hideCloseMandate"
          class="button2 download-button"
          (click)="closeMandate()"
        >
          Close Mandate
        </button>
      </div>
    </div>
  </div>
  <div class="row">
    <app-mandate-detail-summary [mandateInfo]="mandateInfo">
    </app-mandate-detail-summary>
  </div>
  <div class="row">
    <app-mandate-detail-transactions
      [mandateTransactions]="mandateTransactions"
      (fetchMandateTransactions)="fetchMandateTransactions($event)"
    ></app-mandate-detail-transactions>
  </div>
</div>
