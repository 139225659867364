	<app-notify-message 
	[imageSrc]="'assets/images/login/landing_check_image.svg'"
	[title]="'Reset password email sent'"
	[content]="'We have sent you an email to reset your password on your registered email id (if you have one)'"
	[widthOfImage]="'5vw'"
	>
	</app-notify-message>

	<div class="resetPassword-container">
		<span>Not received</span> <button class="resend-button" (click)="handleClick()"> Resend </button>
	</div>
