<div>
    <div class="title-container space-between">
        <div class="section-sub-heading">
            {{ title }}
        </div>
        <div class="button-container">
            <button mat-raised-button type="button" class="btn btn-primary" data-dismiss="modal"
                (click)="toggleEdit()" [hidden]="enableEdit || hideEditAction" [disabled]="!editSections">
                <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit">Edit</span>
            </button>

            <button mat-raised-button type="button" class="btn-secondary" data-dismiss="modal"
                [hidden]="!enableEdit || hideEditAction" (click)="cancelEdit()">
                <span jhiTranslate="entity.action.cancel">Cancel</span>
            </button>
            <button mat-raised-button type="button" class="btn-primary" data-dismiss="modal"
                [hidden]="!enableEdit || hideEditAction" (click)="save()">
                <span jhiTranslate="entity.action.save">Save</span>
            </button>
        </div>
    </div>
</div>
<div class="container">
        <ng-container *ngFor="let field of uiFieldsMap" [ngSwitch]="field?.inputType">
            <div *ngSwitchDefault class="col-lg-3 field">
                <app-input-field *ngIf="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label" [dataObj]="uiFields[field?.propertyKey]"
                    [editable]="enableEdit" [inputLength]="field?.metadata?.inputLength" [isRequired]="field?.required">
                </app-input-field>
                <div [hidden]="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label">
                    <label [for]="field?.propertyKey" class="form-control-label">{{ field?.label }}  
                        <img class="infoIcon" *ngIf="field?.toolTipText?.length" src="assets/images/common/info-tooltip.svg" [alt]="title"
                        [matTooltip]="field?.toolTipText || ''" matTooltipPosition="below"
                        [matTooltipClass]="{'custom-tool-tip': true}" /> </label>
                    <jhi-null-replace [class]="field?.class" [value]="
                        uiFields[field?.propertyKey]?.value
                         | dynamicDataTransform
                        : field?.metadata?.displayFormat
                        : field?.metadata?.format">
                    </jhi-null-replace>
                </div>
            </div>
            <div *ngSwitchCase="'date'" class="col-lg-3 field">
                <app-date-field *ngIf="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label" [dataObj]="uiFields[field?.propertyKey]"
                    [editable]="enableEdit" [isRequired]="field?.required">
                </app-date-field>
                <div [hidden]="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label">
                    <label [for]="field?.propertyKey" class="form-control-label">{{ field?.label }}
                        <img class="infoIcon" *ngIf="field?.toolTipText?.length" src="assets/images/common/info-tooltip.svg" [alt]="title"
                        [matTooltip]="field?.toolTipText || ''" matTooltipPosition="below"
                        [matTooltipClass]="{'custom-tool-tip': true}" />
                    </label>
                    <jhi-null-replace [class]="field?.class" [value]="
                        uiFields[field?.propertyKey]?.value
                         | dynamicDataTransform
                        : field?.metadata?.displayFormat
                        : field?.metadata?.format">
                    </jhi-null-replace>
                </div>
            </div>
            <div *ngSwitchCase="'number'" class="col-lg-3 field">
                <app-number-field *ngIf="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label" [dataObj]="uiFields[field?.propertyKey]"
                    [editable]="enableEdit" [inputLength]="field?.metadata?.inputLength" [isRequired]="field?.required">
                </app-number-field>
                <div [hidden]="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label">
                    <label [for]="field?.propertyKey" class="form-control-label">{{ field?.label }}
                        <img class="infoIcon" *ngIf="field?.toolTipText?.length" src="assets/images/common/info-tooltip.svg" [alt]="title"
                        [matTooltip]="field?.toolTipText || ''" matTooltipPosition="below"
                        [matTooltipClass]="{'custom-tool-tip': true}" />
                    </label>
                    <jhi-null-replace [class]="field?.class" [value]="
                        uiFields[field?.propertyKey]?.value
                         | dynamicDataTransform
                        : field?.metadata?.displayFormat
                        : field?.metadata?.format">
                    </jhi-null-replace>
                </div>
                <mat-error> {{uiFields[field?.propertyKey]?.error}} </mat-error>
            </div>
            <div *ngSwitchCase="'select'" class="col-lg-3 field">
                <app-select-field *ngIf="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label" [dataObj]="uiFields[field?.propertyKey]"
                    [dropDownValues]="field?.dropDownValues" [isRequired]="field?.required"
                    [showSearchBox]="field?.showSearchBox" [disableDefault]="field?.disbableDefault">
                </app-select-field>
                <div [hidden]="enableEdit && uiFields[field?.propertyKey]?.editable" [label]="field?.label">
                    <label [for]="field?.propertyKey" class="form-control-label">{{ field?.label }}
                        <img class="infoIcon" *ngIf="field?.toolTipText?.length" src="assets/images/common/info-tooltip.svg" [alt]="title"
                        [matTooltip]="field?.toolTipText || ''" matTooltipPosition="below"
                        [matTooltipClass]="{'custom-tool-tip': true}" />
                    </label>
                    <jhi-null-replace [class]="field?.class" [value]="
                        uiFields[field?.propertyKey]?.value
                         | dynamicDataTransform
                        : field?.metadata?.displayFormat
                        : field?.metadata?.format">
                    </jhi-null-replace>
                </div>
            </div>
            <ng-container *ngSwitchCase="'breDecision'">
                <div class="col-lg-3 field"></div>
                <div class="col-lg-3 field">
                    <div [label]="field?.label">
                        <label [for]="field?.propertyKey" class="form-control-label">
                            {{ field?.label }}
                            <img class="infoIcon" *ngIf="field?.toolTipText?.length" src="assets/images/common/info-tooltip.svg" [alt]="title"
                            [matTooltip]="field?.toolTipText || ''" matTooltipPosition="below"
                            [matTooltipClass]="{'custom-tool-tip': true}" />
                        </label>
                        <div class="bre-decision">
                            <img *ngIf="uiFields[field?.propertyKey]?.value" [src]="getIcon(uiFields[field?.propertyKey]?.value)" [alt]="uiFields[field?.propertyKey]?.value">
                            <jhi-null-replace [class]="uiFields[field?.propertyKey]?.value" 
                            [value]="uiFields[field?.propertyKey]?.value | titlecase">
                        </jhi-null-replace>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
</div>