import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { KALEIDO_SERVER_API_URL } from "../../../app.constants";

@Injectable()
export class PasswordResetFinishService {
  constructor(private http: HttpClient) {}

  save(keyAndPassword: any): Observable<any> {
    return this.http.post(
      `${KALEIDO_SERVER_API_URL}api/anonymous/account/reset_password/finish`,
      keyAndPassword
    );
  }
}
