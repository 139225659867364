<div class="main-container">
  <div class="title-container">
    <h1>Contact Us</h1>
    <button mat-raised-button class="btn-primary" (click)="backClicked()">
      <mat-icon>arrow_back</mat-icon>
    </button>
  </div>
  <h5>we'd love to hear your suggestions & resolve your queries</h5>
  <mat-card class="example-card">
    <mat-card-content>
      <h3>Technical support</h3>
      <div fxLayout="row wrap">
        <div class="info">
          <h5>Call</h5>
          <h4>1800-5727-907</h4>
          <h6>10 a.m. to 6 p.m.<br />(monday to friday)</h6>
        </div>
        <div class="info">
          <h5>Mail</h5>
          <h4>kicredit@kaleidofin.com</h4>
        </div>
      </div>
      <mat-divider></mat-divider>
      <br />
      <h3>Sales</h3>
      <div fxLayout="row wrap">
        <div class="info">
          <h5>Call</h5>
          <h4>1800-5727-907</h4>
          <h6>10 a.m. to 6 p.m.<br />(monday to friday)</h6>
        </div>
        <div class="info">
          <h5>Mail</h5>
          <h4>kicredit@kaleidofin.com</h4>
        </div>
      </div>
      <mat-divider></mat-divider>
      <br />
      <div class="info">
        <h3>Office location</h3>
        <h5>
          Module no 1A" | 6th Floor | Block A, Phase II<br />IITM Research Park
          | Kanagam Village <br />
          Taramani | Chennai 600 113 | India
        </h5>
      </div>
    </mat-card-content>
  </mat-card>
</div>