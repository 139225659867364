<table class="tid-table">
<caption></caption>
  <thead>
    <tr>
      <ng-container *ngFor="let column of tableColumns">
        <th>
          {{column.displayValue}}
        </th>
      </ng-container>
      <th>
        <div class="match-container"  (keypress)="updateDisplay()" (click)="updateDisplay()">
          <div>
            <span>Action</span>
          </div>
          <mat-icon *ngIf="!showDetail">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="showDetail">keyboard_arrow_up</mat-icon>
        </div>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let hit of hits" [ngClass]="['tid-detail-row', showDetail ? 'show' : 'hide-border']">
      <ng-container *ngFor="let column of tableColumns">
        <td>
          <div>{{hit[column.key]}}</div>
        </td>
      </ng-container>
      <td>
        <div [ngClass]="getVerifyStatus(hit)">
          <img *ngIf="hit['action']" [src]="fetchDisplayIcon(hit['action'])" [alt]="hit['action']">
          <span (click)="verifyAml(hit)"  (keypress)="verifyAml(hit)">
            {{hit["action"]}}
          </span>
        </div>
      </td>
    </tr>
  </tbody>
</table>