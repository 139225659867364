import { Input, Component, OnInit } from "@angular/core";
import { get, isEmpty } from "lodash";

@Component({
  selector: "app-dedupe-form",
  templateUrl: "./dedupe-form.component.html",
  styleUrls: ["./dedupe-form.component.scss"],
})
export class DedupeFormComponent implements OnInit {
  @Input() data: any = null;
  addressInfo: string = "";

  constructor() {}

  ngOnInit(): void {
    this.addressInfo = this.fetchAddressInformation(this.data);
  }

  fetchAddressInformation(addressDTO: any = {}) {
    if (isEmpty(addressDTO)) {
      return "---";
    }
    let addressObject = {
      address1: get(addressDTO, "address1"),
      address2: get(addressDTO, "address2"),
      city: get(addressDTO, "city"),
      district: get(addressDTO, "district"),
      state: get(addressDTO, "state"),
      pincode: get(addressDTO, "pincode"),
    };
    const propertiesToInclude = [
      addressObject.address1,
      addressObject.address2,
      addressObject.city,
      addressObject.district,
      addressObject.state,
      addressObject.pincode,
    ];

    const addressString = propertiesToInclude
      .filter((property) => property !== null && property !== undefined)
      .join(",");

    return addressString;
  }
}
