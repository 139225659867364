<div id="report">
    <div class="report-logo">
        <div class="report-title-container" style="margin-left: 15px">
            <div class="report-logo-container">
                <img src="assets/images/kaleidofin-new-logo.svg" alt="Kaleidofin" />
                <img src="assets/images/lender/dcb-logo.svg" alt="Kaleidofin" />
            </div>
            <div class="section-title-container">
                <div class="section-title"> {{ applicantName ? (applicantName + "_KYC") : "--" }} </div>
                <div class="download-button-container" id="pdf-download-button">
                    <button mat-flat-button class="btn-primary download-button" (click)="downloadPdf()"> Download </button>
                </div>
            </div>
        </div>
        <div>
            <img class="report-static-logo" src="assets/images/common/report-heading-image.svg" alt="Reports" />
        </div>
    </div>
    <div>
        <div class="image-container">
            <img class="applicant-image" [src]="imageUrl" [alt]="applicantName" />
            <div class="applicant-info-container">
                <span class="applicant-name"> {{applicantName}} </span>
                <span class="applicant-info"> {{ekycData?.dob?.value?.bcData | dynamicDataTransform: "date" :
                    "dd-MM-yyyy" || "--"}} .
                    {{ekycData?.gender?.value?.bcData || "--"}}</span>
            </div>
        </div>
    </div>
    <div class="report-details">
        <div class="aadhaar-details">
            <div>
                <table>
                    <caption></caption>
                    <thead>
                        <tr>
                            <th colspan="2">UIDAI Aadhaar Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="row-1">Date and Time</td>
                            <td class="row-1">{{ekycData?.dateTime?.value || '--'}}</td>
                        </tr>
                        <tr>
                            <td class="row-0">Reference Number</td>
                            <td class="row-0">{{ekycData?.referenceNumber?.value || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <table>
                    <caption></caption>
                    <thead>
                        <tr>
                            <th colspan="2">Aadhaar Data</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="row-1">Reference Key</td>
                            <td class="row-1">{{ekycData?.referenceKey?.value || '--'}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="address-details">
            <table>
                <thead>
                    <tr>
                        <th colspan="2">
                            Address Details
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of tableRow; let index = index">
                        <td class="row-{{ (tableRow.length - index - 1) % 2 }}">{{ row?.label || '--'}}</td>
                        <td class="row-{{ (tableRow.length - index - 1) % 2 }}">{{ekycData[row?.propertyKey]?.value?.govData || "--"}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>