<div>
  <div class="section-sub-heading">Eligibility Calculations</div>
  <div class="container">
    <div class="col-lg-3 field" *ngFor="let field of uiFieldsMap">
      <label class="form-control-label" htmlFor="label-1">{{ field?.label }}</label>
      <jhi-null-replace [value]="
            uiFields[field?.propertyKey]?.value
              | dynamicDataTransform : field?.pipe
          "></jhi-null-replace>
    </div>
    <div class="col-lg-3 field"></div>
    <div class="col-lg-3 field">
      <label class="form-control-label" htmlFor="label-2"> BRE Decision </label>
      <div class="decision-container">
        <img class="decision-icon" *ngIf="breDecision !== null" [src]="fetchDecisionIcon(breDecision)" alt="">
        <jhi-null-replace [class]="breDecision" [value]="breDecision"></jhi-null-replace>
      </div>
    </div>
  </div>
</div>