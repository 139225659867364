<mat-dialog-title>
    <h2 mat-dialog-title class="title-text">Alert</h2>
  </mat-dialog-title>
  <mat-dialog-content>
    <p>
      Changing the product code will change the loan type. Do you want to proceed?
    </p>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-button class="btn-secondary cancel-button" (click)="onNoClick()">No</button>
    <button mat-button class="btn-primary" (click)="onYesClick()">Yes</button>
  </mat-dialog-actions>
  