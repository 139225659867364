export class DedupeData {
  public id;
  public title;
  public type;
  public customerInformation;
  constructor(id: number, title: string, type:string, customerInformation: Object) {
    this.id = id;
    this.title = title;
    this.type = type;
    this.customerInformation = customerInformation;
  }
}

export interface DedupeResponseDTO {
  id: number,
  partnerId: number,
  loanId: number,
  productCode: string,
  loanNumber: number,
  createdDate: string,
  dedupeReferenceId: number,
  dedupeMatchReferenceId: number,
  name: string,
  dedupeType: string,
  dedupeParameter: string,
  dedupeReferenceType: string,
  selected?:boolean,
  }
  
  export const DEFAULT_DEDUPE_ERROR_TEXT = "This customer can not be marked as new customer since mobile number is duplicate.";
  export const DEFAULT_DEDUPE_NEW_CUSTOMER_TEXT = "Marked as new customer  with customer ID ";
  export const DEFAULT_DEDUPE_OLD_CUSTOMER_TEXT = "Application is marked with existing customer ID ";