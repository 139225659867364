import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-help-and-support',
  templateUrl: './help-and-support.component.html',
  styleUrls: ['./help-and-support.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HelpAndSupportComponent {

}
