import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-deviations-confirmation-popup",
  templateUrl: "./deviations-confirmation-popup.component.html",
  styleUrls: ["./deviations-confirmation-popup.component.scss"],
})
export class DeviationsConfirmationPopupComponent implements OnInit {
  comment: string = null;
  approver: string = "Credit Analyst";
  decision: string = "approve";

  constructor(
    @Inject(MAT_DIALOG_DATA) private readonly data,
    private readonly dialogRef: MatDialogRef<DeviationsConfirmationPopupComponent>
  ) {}

  ngOnInit(): void {
    this.approver = getProperty(
      this.data,
      "deviationAuthority",
      "Credit Analyst"
    );
    this.decision =
      getProperty(this.data, "decision", "approve").toLowerCase() === "approved"
        ? "approve"
        : "reject";
  }

  setComment(): void {
    this.dialogRef.close({
      approverComment: this.comment,
    });
  }

  closeDialog(): void {
    this.dialogRef.close({
      forceClosed: true,
    });
  }
}
