<div class="user-profile-navigation">
    <div class="avatar-container">
        <app-avatar-badge [username]="userInfo.fullName" [avatarRadius]="'100px'" [fontSize]="'30px'">
        </app-avatar-badge>
    </div>
    <div class="name-container">
        <span> {{userInfo.fullName}} </span>
    </div>
    <div class="navigation-container">
        <span *ngFor="let nav of navigationArray; let i = index" (click)="changeNavigation(i, nav?.contentType)"
            [ngClass]="{'active-class' : activeIndex === i}">
            {{ nav?.label }}
        </span>
    </div>
</div>