<div
  class="container"
  appDnd
  (fileDropped)="fileBrowseHandler($event)"
  *ngIf="files.length === 0 && selectedDocuments.length === 0"
>
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    multiple
    [accept]="getAcceptedFileTypes()"
    (change)="fileBrowseHandler($event)"
  />
  <br />
  <h3>Drag & drop or Attach file</h3>
  <h6>
    {{ uploadInfoText }}
  </h6>
  <label for="fileDropRef">Browse File</label>
  <br />
</div>
<div class="files-list" *ngIf="files.length > 0">
  <div class="single-file" *ngFor="let file of files; let i = index">
    <div class="common-flex-container">
      <div class="file-icon">
        <img [src]="file?.fileUrl" alt="Doc" class="image-default-style" />
      </div>
      <p class="name">
        {{ file?.name }}
      </p>
    </div>
    <div class="common-flex-container">
      <p class="size">
        {{ formatBytes(file?.size) }}
      </p>
      <div class="delete" (click)="deleteFile(i)" (keypress)="deleteFile(i)">
        <button mat-button class="btn btn-primary">Remove</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="files.length > 0 || selectedDocuments?.length > 0">
  <input
    type="file"
    #fileDropRef
    id="fileDropRef"
    [multiple]="canAllowMultiple"
    class="relative-input"
    [accept]="getAcceptedFileTypes()"
    (change)="fileBrowseHandler($event)"
  />
  <label class="add-file" for="fileDropRef">
    <mat-icon>+</mat-icon>
    <p>Add another file</p>
  </label>
</div>
