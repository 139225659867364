import { Component } from "@angular/core";
import { get } from "lodash";
import { LENDER_CONFIGURATIONS } from "src/app/constants/lender.config";
import { AssociateLenderService } from "src/app/entities/kaleido-credit/services/associate-lender/associate-lender.service";
import { Router } from "@angular/router";

@Component({
  selector: "ig-main",
  templateUrl: "./main.component.html",
})
export class IgMainComponent {
  filteredEndpoints: string[] = [
    ".*/api/lender/ui/loan-review/info-section/*",
    ".*/api/anonymous/lender/ui/.*",
    ".*api/lender/ui/bre-section*",
    ".*api/lender/ui/review-section*",
    ".*api/lender/ui/demand-schedule*"
  ];

  url: string = null;

  useFooter: boolean = false;

  constructor(
    private readonly associateLenderService: AssociateLenderService,
    private readonly router: Router
   ) {
    this.router.events.subscribe((url: any) => {
      if (url?.url) {
        this.url = url?.url;
      }
    });
  }

  ngOnInit() {
    this.associateLenderService
      .getLenderCodeSubject()
      .subscribe((lenderCode) => {
        let config = LENDER_CONFIGURATIONS[lenderCode];
        this.useFooter = get(config, "useFooter");
      });
  }

  checkFooter(): boolean {
    const restrictedRoutes: Array<string> = ["/", "/reset", "reset/finish", "/lockout", "/expired"];
    return !restrictedRoutes.includes(this.url);
  }

}
