<div class="dialog-container">
  <div mat-dialog-title class="dialog-title">
    Confirmation
    <mat-icon class="closeicon" (click)="cancel()" (keypress)="cancel()" tabindex="0">close</mat-icon>
  </div>
  <div mat-dialog-content class="row">
    <div class="col upload-container">
      <app-file-upload
        (passFileSelected)="receiveFileSelected($event)"
        [uploadInfoText]="uploadInfoText"
        [acceptedFileTypes]="acceptedFileTypes"
        [fileUploadLimit]="fileUploadLimit"
      ></app-file-upload>
    </div>
    <br />
    <div class="col">
      <mat-form-field appearance="none">
        <input
          [(ngModel)]="remarks"
          matInput
          placeholder="Add Remarks"
          required
        />
      </mat-form-field>
    </div>
  </div>
  <div mat-dialog-actions>
    <div class="action-buttons">
      <button mat-button class="button4 cancel" (click)="cancel()">
        Cancel
      </button>
      <button
        mat-button
        class="btn btn-primary"
        cdkFocusInitial
        [disabled]="!remarks || !isFilesSelected"
        (click)="approve()"
      >
        Approve
      </button>
    </div>
  </div>
</div>
