
export class FamilyDetails {
    constructor(
        public id?: number,
        public version?: number,
        public customerId?: number,
        public relationName?: any,
        public relationship?: any,
        public gender?: any,
        public dob?: any,
        public occupation?: any,
        public educationalStatus?: any,
        public isCustomer?: any,
        public mobileNumber?: any
) {
    }

}
