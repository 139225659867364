<div class="login-page" *ngIf="!resetPasswordAcknowledged">
    <img class="header" src="assets/images/kaleidofin-new-logo.svg" alt="Admin Tool"/>
    <mat-card class="login-card">
        <mat-card-content>
            <h3>Forgot Password</h3>
            <h5>
                Enter your registered email id we will send a link to reset your
                password.
            </h5>

            <form [formGroup]="resetForm" (ngSubmit)="reset()" autocomplete="off" novalidate class="clearfix">
                <mat-form-field style="margin-bottom: 0; width: 260px">
                    <input matInput type="email" name="email" id="email" placeholder="Email" formControlName="email"
                        [errorStateMatcher]="matchers" required />
                    <mat-error *ngIf="resetForm.get('email').hasError('required')">
                        Please enter your Email
                    </mat-error>
                    <mat-error *ngIf="resetForm.get('email').hasError('pattern')">
                        Your Email is invalid
                    </mat-error>
                </mat-form-field>
                <mat-error *ngIf="errors">
                    <br />
                    {{ errors }}
                </mat-error>
                <br />
                <div class="d-flex">
                    <div class="w-100 top-margin">
                        <button mat-raised-button class="button1" type="submit" [disabled]="resetForm.invalid">
                            <span>Reset Password</span>
                        </button>
                    </div>
                </div>
            </form>

            <br />
            <div class="link">
                <a href="javascript:void(0)" (click)="moveToLogin()" style="text-decoration: none; color: #ff7b52"
                    class="k-font-10">
                    <span>Login</span>
                </a>
            </div>
        </mat-card-content>
    </mat-card>
</div>

<div class="login-page" *ngIf="resetPasswordAcknowledged">
    <mat-card class="login-card">
        <mat-card-content>
            <div>
                <mat-icon class="mat-success-icon">check_circle_outline</mat-icon>
                <h3>Reset password link sent</h3>
                <br />
                <h5>
                    We have sent you a link to
                    <b>{{ this.resetForm.get("email")?.value }} </b>to reset your password
                    please check your inbox
                </h5>
                <br />
                <div class="link" *ngIf="!resendCompleted">
                    Link not received?
                    <a href="javascript:void(0)" (click)="resendEmail()" style="text-decoration: none; color: #ff7b52"
                        class="k-font-10">
                        <span>Resend</span>
                    </a>
                </div>
                <br />
                <div class="link">
                    Need help?
                    <a href="javascript:void(0)" (click)="moveToContacts()"
                        style="text-decoration: none" class="k-font-10">
                        <span>Contact us</span>
                    </a>
                </div>
                <br />
                <div class="link">
                    <a href="javascript:void(0)" (click)="moveToLogin()" style="text-decoration: none; color: #ff7b52"
                        class="k-font-10">
                        <span>Login</span>
                    </a>
                </div>
            </div>
        </mat-card-content>
    </mat-card>
</div>
<ig-footer></ig-footer>