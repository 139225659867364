<ng-container *ngIf="uiFieldsMap.length > 0">
  <app-dynamic-render-component
  [title]="'Loan Overview'"
  [uiFields]="uiFields"
  [uiFieldsMap]="uiFieldsMap"
  [loanId]="loanId"
  [editSections]="editSections"
  [uiApiKey]="'LOAN_OVERVIEW'"
>
</app-dynamic-render-component>
</ng-container>