import { Component, OnInit, Input } from "@angular/core";
import { get } from "lodash";
import { AUTHORITES } from "../../../constants/authorization.constants";

@Component({
  selector: "app-pre-sanction-docs",
  templateUrl: "./pre-sanction-docs.component.html",
  styleUrls: ["./pre-sanction-docs.component.scss"],
})
export class PreSanctionDocsComponent implements OnInit {
  @Input() loanDetailDocuments: any[];
  @Input() partnerId: any;
  @Input() loanId: any;
  panelOpenState:boolean = false;
  documentTypes: any[] = [];
  authority = AUTHORITES.UNDERWRITINGSCREEN_PRESANCTIONCONDITIONALLYREJECT;
  constructor() {}

  ngOnInit(): void {
    this.documentTypes = Object.keys(this.loanDetailDocuments|| {});
    this.panelOpenState = get(this.documentTypes, "length", 0) > 0;
  }
}
