import { PEPStatus } from "../customer-review/kyc-review/PEPComplianceList";

export class Customer {
    constructor(
        public id?: number,
        public version?: number,
        public name?: string,
        public gender?: string,
        public dateOfBirth?: any,
        public fatherName?: string,
        public motherName?: string,
        public maritalStatus?: string,
        public mobileNumber?: number,
        public secondaryMobileNumber?: number,
        public email?: string,
        public address1?: string,
        public address2?: string,
        public address3?: string,
        public city?: any,
        public district?: any,
        public state?: any,
        public country?: string,
        public aadhaarNumber?: string,
        public aadhaarNumberFileId?: any,
        public panNumber?: number,
        public spouseName?: string,
        public spouseDateOfBirth?: any,
        public numberOfDependents?: number,
        public nomineeName?: string,
        public nomineeRelationship?: string,
        public nomineeAddress?: string,
        public isEdited?: boolean,
        public registeredMobileNumber?: string,
        public initilalQuestion?: boolean,
        public finalQuestion?: boolean,
        public initilalRecommdation?: boolean,
        public finalRecommdation?: boolean,
        public status?: any,
        public aadhaarEkyc?: boolean,
        public aadhaarEsign?: boolean,
        public esignTries?: number,
        public creditBureauCheck?: boolean,
        public personaScore?: boolean,
        public longitude?: string,
        public latitude?: string,
        public pinCode?: string,
        public errorCode?: string,
        public enrollmentDate?: any,
        public partnerCustomerId?: string,
        public customerNumber?: string,
        public bankAccountNum?: string,
        public ifscCode?: string,
        public micrCode?: string,
        public bankName?: string,
        public bankAccountType?: string,
        public accountHolderName?: string,
        public pekrnNumber?: string,
        public partnerId?: number,
        public branchId?: number,
        public groupId?: string,
        public nomineeDateOfBirth?: any,
        public aadhaarFrontFileId?: number,
        public aadhaarBackFileId?: number,
        public panNumberFileId?: number,
        public chequeFileId?: number,
        public profilePhotoFileId?: number,
        public signatureFileId?: number,
        public ekycResponseCode?: string,
        public ekycResponseMessage?: string,
        public ekycDate?: any,
        public isSeconadaryMobileSameAsPrimary?: boolean,
        public isAdminEditOfCustomer?: boolean,
        public customerCbCheckRequired?: boolean,
        public partnerCbCheckRequired?: boolean,
        public primaryMobileNumber?: number,
        public profileCompleted?: boolean,
        public kycApplicationFormId?: string,
        public mobileNumberOwner?: string,
        public debitCard?: string,
        public debitCardBelongsTo?: string,
        public bankCode?: string,
        public bankBranch?: string,
        public bankAddress?: string,
        public bankContact?: string,
        public bankCity?: string,
        public bankDistrict?: string,
        public bankState?: string,
        public kycType?: string,
        public enrolmentFeePaid?: boolean,
        public enrollmentMode?: string,
        public profile?: string,
        public centerId?: number,
        public reviewStatus?: any,
        public rejectStatus?: boolean,
        public rejectReason?: string,
        public kycOnboardingStatus?: string,
        public highmarkStatus?: string,
        public customerDisplayName?: string,
        public isTestCustomer?: boolean,
        public customerState?: number,
        public poiDocType?: string,
        public poaDocType?: string,
        public kycReviewStatus?: any,
        public kycReviewAcceptedDate?: any,
        public kycReviewedBy?: string,
        public merchantId?: string,
        public kycTechReviewCheck?: boolean,
        public techIssueStatus?: boolean,
        public holdStatus?: boolean,
        public techIssueReason?: string,
        public reviewOptionList?: any,
        public reasonOptionsModel?: any,
        public techReviewOptionList?: any,
        public techReasonOptionsModel?: any,
        public holdReviewOptionList?: any,
        public holdReasonOptionsModel?: any,
        public accountVerificationStatus?: any,
        public accountVerificationDetailedStatus?: any,
        public pepStatus?: PEPStatus,
        public occupationType?:string,
        public spouseAadhaarNo?: any,
        public fatherAadhaarNo?: any,
        public spouseVoterId?: any,
        public fatherVoterId?: any,
        public spouseRationNo?: any,
        public fatherRationNo?: any,
        public spouseMobileNo?: any,
        public fatherMobileNo?: any,
        public maskedAadhaar?: any,
        public aadhaarVerificationStatus?: any,
        public aadhaarReferenceId?: any,
        public poaNumber?: any,
        public poiNumber?: any,
        
        
    ) {

        this.isSeconadaryMobileSameAsPrimary = true;
        this.isAdminEditOfCustomer = false;

    }
}
