<mat-accordion>
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <div class="expansion-panel-title-container">
        <div class="section-title">Land and Crop Details</div>
        <div>
          <button
            [hidden]="enableEdit"
            mat-raised-button
            *ngIf="panelOpenState"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="addCropInformation($event)"
            [disabled]="!editSections || !hasAuthority"
          >
            Add Crop</button
          >&nbsp;&nbsp;&nbsp;&nbsp;
          <button
            [hidden]="enableEdit"
            mat-raised-button
            *ngIf="panelOpenState"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="toggleEditDetails($event)"
            [disabled]="!editSections || !hasAuthority"
          >
            <span class="fa fa-pencil"></span>&nbsp;<span
              jhiTranslate="entity.action.edit"
              >Edit</span
            >
          </button>
        </div>
        <div class="confirm-buttons" [hidden]="!enableEdit">
          <button
            mat-raised-button
            type="button"
            class="btn-secondary"
            data-dismiss="modal"
            (click)="cancel($event)"
          >
            <span jhiTranslate="entity.action.cancel">Cancel</span>
          </button>
          <button
            mat-raised-button
            type="button"
            class="btn-primary"
            data-dismiss="modal"
            (click)="save($event)"
          >
            <span jhiTranslate="entity.action.save">Save</span>
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>

    <div
      *ngFor="let cropInformation of landInformationSections; let index = index"
    >
      <app-crop-information
        [loanId]="loanId"
        [uiFields]="cropInformation?.fields"
        [uiFieldsMap]="getUiFieldMap(cropInformation)"
        [title]="cropInformation?.title"
        [hideEditAction]="true"
        [enableEdit]="enableEdit"
        [customButtons]="cropInformation?.customButtons"
      >
      </app-crop-information>
      <hr class="section-hr" />
    </div>
    <div *ngFor="let cropInformation of cropsSections; let index = index">
      <app-crop-information
        [loanId]="loanId"
        [uiFields]="cropInformation?.fields"
        [uiFieldsMap]="getUiFieldMap(cropInformation)"
        [title]="cropInformation?.title"
        [hideEditAction]="true"
        [enableEdit]="enableEdit"
        [customButtons]="cropInformation?.customButtons"
        [sectionTitle]="cropInformation?.title"
      >
      </app-crop-information>
      <hr class="section-hr" />
    </div>
    <div
      *ngFor="
        let cropInformation of sub30ProductSchemeSections;
        let index = index
      "
    >
      <app-crop-information
        [loanId]="loanId"
        [uiFields]="cropInformation?.fields"
        [uiFieldsMap]="getUiFieldMap(cropInformation)"
        [title]="cropInformation?.title"
        [hideEditAction]="true"
        [enableEdit]="enableEdit"
        [customButtons]="cropInformation?.customButtons"
        [sectionTitle]="cropInformation?.title"
      >
      </app-crop-information>
      <hr class="section-hr" />
    </div>
  </mat-expansion-panel>
</mat-accordion>
