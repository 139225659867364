<div class="mandate-table-container">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
      <td mat-cell *matCellDef="let user">
        <div class="flex-box">
          <span [ngClass]="['status-indicator', user.reportStatus]"></span>
          &nbsp; &nbsp;
          <div>
            {{ user.createdDate | date : "dd MMM yyyy" }}
            <div class="sub-text">{{ user.createdDate | date : "HH:mm" }}</div>
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="fileName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>FILE NAME</th>
      <td
        mat-cell
        class="fileName"
        *matCellDef="let user"
        [matTooltip]="user.fileName"
      >
        {{ user.fileName }}
      </td>
    </ng-container>

    <ng-container matColumnDef="reportType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>REPORT TYPE</th>
      <td mat-cell *matCellDef="let user">
        <div>
          <span *ngIf="user?.siReportRequest?.customerName">
            {{ user?.siReportRequest?.customerName }}
          </span>
          <div [class]="user?.siReportRequest?.customerName ? 'sub-text' : ''">
            {{ reportType[user?.siReportRequest?.reportType] }}
          </div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DURATION</th>
      <td mat-cell *matCellDef="let user">
        {{ durationViewMapper[user?.siReportRequest?.duration] }}
        <div
          class="sub-text"
          *ngIf="user?.siReportRequest?.duration === 'dateRange'"
        >
          {{ user?.siReportRequest?.startDate }} -
          {{ user?.siReportRequest?.endDate }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
      <td mat-cell *matCellDef="let user" [ngClass]="[user?.reportStatus]">
        {{ statusViewMapper[user?.reportStatus] }}
      </td>
    </ng-container>
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef>ACTION</th>
      <td mat-cell *matCellDef="let user">
        <button
          mat-icon-button
          [disabled]="!user?.fileId"
          (click)="downloadReports(user?.fileId, user?.fileName)"
        >
          <mat-icon class="download-report-button">save_alt</mat-icon>
        </button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns; let i = index"
      class="row-even"
    ></tr>
  </table>

  <mat-paginator
    *ngIf="resultsLength !== 0"
    [pageSize]="pageSize"
    [length]="resultsLength"
    [showFirstLastButtons]="true"
    [pageIndex]="currentPage"
    (page)="onPageChange($event)"
    [showPageSizeOptions]="false"
  ></mat-paginator>
</div>

<ng-container *ngIf="resultsLength === 0">
  <div class="no-downloads">
    <mat-icon>save_alt</mat-icon>
    No Downloads
  </div>
</ng-container>
