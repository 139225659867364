<mat-form-field appearance="outline" [matTooltip]="toolTipLabel"
    [matTooltipDisabled]="!showToolTip"
    matTooltipPosition="above">
    <mat-label>{{ label }}</mat-label>
    <input *ngIf="showInput" matInput  placeholder="Enter value" [(ngModel)]="dataObj.value" />
    <mat-select *ngIf="!showInput" [(value)]="dataObj.value" [required]="isRequired" (selectionChange)="showInputField()">
        <mat-option value="other">Other (Please Specify) </mat-option>
        <mat-option *ngFor="let option of dropDownValues" [value]="option">
            {{ option }}
        </mat-option>
    </mat-select>
</mat-form-field>