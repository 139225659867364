<div class="card-title">
    <form name="aboutTheLoan" novalidate #editForm="ngForm">
        <div class="card-body">
            <div class="card-title">
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.totalProcessingFee" for="field_total_processing_fee">Total Processing Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="total_processing_fee" id="field_total_processing_fee"
                                   [(ngModel)]="loanFee.totalProcessingFee" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.totalProcessingFee"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.kcplProcessingFeePer" for="field_kcpl_processing_fee_per">KCPL Processing Fee Per</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="kcpl_processing_fee_per" id="field_kcpl_processing_fee_per"
                                   [(ngModel)]="loanFee.kcplProcessingFeePer" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.kcplProcessingFeePer"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.kcplProcessingFeeAmount" for="field_kcpl_processing_fee_amount">KCPL processing Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="kcpl_processing_fee_amount" id="field_kcpl_processing_fee_amount"
                                   [(ngModel)]="loanFee.kcplProcessingFeeAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.kcplProcessingFeeAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.partnerProcessingFeePer" for="field_partner_processing_fee_per">Partner processing fee %</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="partner_processing_fee_per" id="field_partner_processing_fee_per"
                                   [(ngModel)]="loanFee.partnerProcessingFeePer" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.partnerProcessingFeePer"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.partnerProcessingFeeAmount" for="field_partner_processing_fee_amount">Partner Processing Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="partner_processing_fee_amount" id="field_partner_processing_fee_amount"
                                   [(ngModel)]="loanFee.partnerProcessingFeeAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.partnerProcessingFeeAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.insurancePremiumAmount" for="field_insurance_premium_amount">Insurance Premium Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="insurance_premium_amount" id="field_insurance_premium_amount"
                                   [(ngModel)]="loanFee.insurancePremiumAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.insurancePremiumAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.stampDutyAmount" for="field_stamp_duty_amount">Stamp Duty Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="stamp_duty_amount" id="field_stamp_duty_amount"
                                   [(ngModel)]="loanFee.stampDutyAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.stampDutyAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.otherFeeAmount" for="field_other_fee_amount">Other Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="other_fee_amount" id="field_other_fee_amount"
                                   [(ngModel)]="loanFee.otherFeeAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.otherFeeAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.kcplOtherFeePer" for="field_kcpl_other_fee_per">KCPL Other Fee Per</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="kcpl_other_fee_per" id="field_kcpl_other_fee_per"
                                   [(ngModel)]="loanFee.kcplOtherFeePer" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.kcplOtherFeePer"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.kcplOtherFeeAmount" for="field_kcpl_other_fee_amount">KCPL Other Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="kcpl_other_fee_amount" id="field_kcpl_other_fee_amount"
                                   [(ngModel)]="loanFee.kcplOtherFeeAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.kcplOtherFeeAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.partnerOtherFeePer" for="field_partner_other_fee_per">Partner Other Fee Per</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="partner_other_fee_per" id="field_partner_other_fee_per"
                                   [(ngModel)]="loanFee.partnerOtherFeePer" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.partnerOtherFeePer"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.loanFee" [inputErrors]="editForm.controls.loanFee?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.loanFee.partnerOtherFeeAmount" for="field_partner_other_fee_amount">Partner Other Fee Amount</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="partner_other_fee_amount" id="field_partner_other_fee_amount"
                                   [(ngModel)]="loanFee.partnerOtherFeeAmount" pattern="^[0-9]+$"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="loanFee.partnerOtherFeeAmount"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
            </div>
        </div>
    </form>

