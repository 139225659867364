<div class="main-container">
  <div fxLayout="row wrap" style="justify-content: space-between">
    <div>
      <h1>Payments</h1>
      <h5>here your all customers' payments details</h5>
    </div>
    <custom-datepicker
      (datesEventEmitter)="getDateRange($event)"
    ></custom-datepicker>
  </div>
  <div>
    <table
      mat-table
      [dataSource]="data"
      matSort
      (matSortChange)="sortData($event)"
      id="listtable"
    >
    <caption></caption>
      <!-- Date Column -->
      <ng-container matColumnDef="d.createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.demandCreatedDate | date: "dd MMM yyyy" }}</h4>
          <h5>{{ row.demandCreatedDate | date: "mediumTime" }}</h5>
        </td>
      </ng-container>

      <!-- Customer Column -->
      <ng-container matColumnDef="c.name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>CUSTOMER INFO</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.customerName }}</h4>
          <h5>{{ row.customerId }}</h5>
        </td>
      </ng-container>

      <!-- Product Column -->
      <ng-container matColumnDef="product">
        <th mat-header-cell *matHeaderCellDef>PRODUCT INFO</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.accountType }}</h4>
          <h5>{{ row.accountNumber }}</h5>
        </td>
      </ng-container>

      <!-- Branch Column -->
      <ng-container matColumnDef="branch">
        <th mat-header-cell *matHeaderCellDef>BRANCH & CENTER</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.branch }}</h4>
          <h5>{{ row.center }}-{{ row.centerCode }}</h5>
        </td>
      </ng-container>

      <!-- Payment Column -->
      <ng-container matColumnDef="payment">
        <th mat-header-cell *matHeaderCellDef>PAYMENT MODE</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{ row.paymentMode }}</h4>
          <h5>{{ row.paymentModeId }}</h5>
        </td>
      </ng-container>

      <!-- Transaction Column -->
      <ng-container matColumnDef="transaction">
        <th mat-header-cell *matHeaderCellDef>TRANSACTION</th>
        <td mat-cell *matCellDef="let row">
          <h4>
            {{ row.totalAmount | currency: "INR":"symbol" }}
            <button
              mat-icon-button
              matTooltip="{{ getTooltip(row) }}"
              [matTooltipPosition]="tooltipPosition"
              disableRipple="true"
            >
              <mat-icon class="material-icons-outlined"> info </mat-icon>
            </button>
          </h4>
        </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell *matCellDef="let row">
          <div class="success" *ngIf="row.status === 'Success'">
            {{ row.status }}
          </div>
          <div class="failure" *ngIf="row.status === 'Failure'">
            {{ row.status }}
          </div>
          <div
            class="other"
            *ngIf="row.status !== 'Success' && row.status !== 'Failure'"
          >
            {{ row.status }}
          </div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    [pageSize]="pageSize"
    [length]="resultsLength"
    [showFirstLastButtons]="true"
    [pageIndex]="currentPage"
    (page)="handlePage($event)"
  ></mat-paginator>
</div>
