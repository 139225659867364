import { Component, Input } from '@angular/core';
import { getProperty } from 'src/app/utils/app.utils';

@Component({
  selector: 'app-select-with-input',
  templateUrl: './select-with-input.component.html'
})
export class SelectWithInputComponent {
  @Input() dropDownValues: Array<string> = [];
  @Input() dataObj: any = {};
  @Input() label: string = "";
  @Input() isRequired: boolean = false; 
  showInput: boolean = false;

  showInputField(): void {
    const value: string = getProperty(this.dataObj,"value","");
    if(value === "other") {
      this.dataObj.value = "";
      this.showInput = true;
    }
    else {
      this.showInput = false;
    }
  }

}
