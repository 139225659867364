<app-mfi-cam-customer-info [customerInfo]="reportData">
</app-mfi-cam-customer-info>

<app-mfi-cam-household-profile [houseHoldProfile]="reportData?.houseHoldProfile">
</app-mfi-cam-household-profile>

<app-mfi-cam-household-income [reportData]="reportData">
</app-mfi-cam-household-income>

<app-mfi-cam-other-yearly-income [reportData]="reportData">
</app-mfi-cam-other-yearly-income>

<app-mfi-cam-regular-expenses [reportData]="reportData">
</app-mfi-cam-regular-expenses>

<app-mfi-cam-irregular-expenses [reportData]="reportData">
</app-mfi-cam-irregular-expenses>