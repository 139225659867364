<div class="login-page" *ngIf="!resetPasswordAcknowledged">
  <img class="header" src="assets/images/kaleidofin-new-logo.svg" alt="Admin Tool">
  <mat-card class="login-card">
    <mat-card-content>
      <h3>Change Password</h3>
      <h5>Enter your new password for your kaleidofin admin tool account</h5>
      <div class="row justify-content-center align-items-center">
        <div class="col-md-12">
          <form #registerForm="ngForm" (ngSubmit)="finishReset()" novalidate class="clearfix">
            <mat-form-field class="custom-form-field">
              <input matInput [type]="hide ? 'password' : 'text'" id="password" name="password" #password="ngModel"
                placeholder="Password" [(ngModel)]="resetAccount.password" minlength="8" maxlength="50" required />
              <button mat-icon-button matSuffix type="button">
                <mat-icon class="k-password-visibilty" style="font-size: x-large" (keypress)="hide =!hide" tabindex="0" (click)="hide = !hide">{{ hide ?
                  "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="password.dirty && password.invalid">
                <small *ngIf="password.errors['required']">
                  Password is required.
                </small>
                <small *ngIf="password.errors['minlength']">
                  Password must be at least 8 characters.
                </small>
                <small *ngIf="password.errors['maxlength']">
                  Password cannot be longer than 50 characters.
                </small>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="custom-form-field">
              <input matInput [type]="hide ? 'password' : 'text'" id="confirmPassword" name="confirmPassword"
                #confirmPasswordInput="ngModel" placeholder="Confirm Password" [(ngModel)]="confirmPassword"
                minlength="8" maxlength="50" required />
              <mat-error *ngIf="
              confirmPasswordInput.dirty && confirmPasswordInput.invalid
            ">
                <small *ngIf="confirmPasswordInput.errors['required']">
                  Password is required.
                </small>
                <small *ngIf="confirmPasswordInput.errors['minlength']">
                  Password must be at least 8 characters.
                </small>
                <small *ngIf="confirmPasswordInput.errors['maxlength']">
                  Password cannot be longer than 50 characters.
                </small>
              </mat-error>
            </mat-form-field>

            <br><br>
            <div *ngIf="!resetPasswordAcknowledged">
              <div class="d-flex">
                <div class="w-100 top-margin">
                  <button mat-raised-button class="reset-button" type="submit" [disabled]="registerForm.form.invalid">
                    <span>Change Password</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

<div class="login-page" *ngIf="resetPasswordAcknowledged">
  <mat-card class="login-card">
    <mat-card-content>
      <div>
        <mat-icon class="mat-success-icon">check_circle_outline</mat-icon>
        <h3>Password changed!</h3>
        <br>
        <h5>
          Your password has been changed successfully
        </h5>
        <br>
        <br>
        <div class="link">
          <a href="javascript:void(0)" (click)="navigateToLogin()" style="text-decoration: none; color: #FF7B52;"
            class="k-font-10">
            <span>Login</span>
          </a>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>