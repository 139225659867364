<mat-form-field class="custom-form-field" appearance="standard">
    <input matInput format="dd-MM-yyyy" [formControl]="dateFormControl" [matDatepicker]="dueDate"
     [value]="getDate()" [min]="minDate" (dateChange)="changeDate($event)" />
    <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
    <mat-datepicker #dueDate></mat-datepicker>
</mat-form-field>
<mat-error *ngIf="dateFormControl?.hasError('min')"> 
    Due date must be greater than current date.
</mat-error>
<mat-error *ngIf="dateFormControl?.hasError('max')"> 
    Due date must be within 6 months from current date.
</mat-error>
