<div>
  <mat-accordion>
    <mat-expansion-panel
      [expanded]="openBreSection"
      (opened)="openBreSection = true"
      (closed)="openBreSection = false"
    >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="bre-title-container">
            <div class="section-title">BRE</div>
            <div class="bre-action-buttons" *ngIf="openBreSection">
              <button
                mat-raised-button
                class="btn btn-primary"
                (click)="recalculateBre($event)"
                [disabled]="!enableRecalculateBre"
              >
                Recalculate BRE
              </button>
              <button
                [hidden]="fromEntry"
                mat-raised-button
                class="btn btn-primary"
                (click)="openBreReport($event)"
              >
                <mat-icon svgIcon="pdf-icon"></mat-icon>
                View Report
              </button>
            </div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="bre-data-container">
        <app-eligibility-norms
          [uiFields]="eligibilityNorms"
          [loanId]="loanApplicationId"
          [editSections]="editSections"
          (saveData)="save()"
        ></app-eligibility-norms>
        <app-bre-eligibility-calculation
          [uiFields]="eligibilityCalculations"
        ></app-bre-eligibility-calculation>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
