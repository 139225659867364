<div class="main-container">
  <h1>Discover</h1>
  <h5>
    Products to help assess credit worthiness and collect payments from
    customers
  </h5>
  <div
    fxLayout="row wrap"
    fxLayout.lt-sm="column"
    fxLayoutGap="30px"
    fxFlexAlign="center"
  >
    <mat-card
      fxFlex="calc(33%-30px)"
      fxFlex.lt-sm="calc(100%-30px)"
      class="example-card"
    >
      <img
        mat-card-image
        src="../../../../assets/images/discover/upi-autopay.svg"
        alt=""
      />
      <mat-card-content>
        <h2>UPI Auto pay</h2>
        <h5>Unlock growth with UPI AutoPay</h5>
        <br />
        <div class="info">
          <h4>Inbuilt Scheduler</h4>
          <h5>
            takes care of subsequent debits, pre-debit notifications and
            Invoicing
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Complete flexibility</h4>
          <h5>
            Add a trial period, upfront amounts or add on an amount during a
            cycle
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Automatic retries</h4>
          <h5>
            Automatically retry failed debits and give the customer an option to
            change the authorised account.
          </h5>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Know more</button>
      </mat-card-actions>
    </mat-card>
    <mat-card
      fxFlex="calc(33%-30px)"
      fxFlex.lt-sm="calc(100%-30px)"
      class="example-card"
    >
      <img
        mat-card-image
        src="../../../../assets/images/discover/ki-score.svg"
        alt=""
      />
      <mat-card-content>
        <h2>Ki Score</h2>
        <h5>Unlock growth with UPI AutoPay</h5>
        <br />
        <div class="info">
          <h4>Inbuilt Scheduler</h4>
          <h5>
            takes care of subsequent debits, pre-debit notifications and
            Invoicing
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Complete flexibility</h4>
          <h5>
            Add a trial period, upfront amounts or add on an amount during a
            cycle
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Automatic retries</h4>
          <h5>
            Automatically retry failed debits and give the customer an option to
            change the authorised account.
          </h5>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Know more</button>
      </mat-card-actions>
    </mat-card>
    <mat-card
      fxFlex="calc(33%-30px)"
      fxFlex.lt-sm="calc(100%-30px)"
      class="example-card"
    >
      <img
        mat-card-image
        src="../../../../assets/images/discover/e-mandate.svg"
        alt=""
      />
      <mat-card-content>
        <h2>e-Mandate</h2>
        <h5>Unlock growth with UPI AutoPay</h5>
        <br />
        <div class="info">
          <h4>Inbuilt Scheduler</h4>
          <h5>
            takes care of subsequent debits, pre-debit notifications and
            Invoicing
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Complete flexibility</h4>
          <h5>
            Add a trial period, upfront amounts or add on an amount during a
            cycle
          </h5>
        </div>
        <br />
        <div class="info">
          <h4>Automatic retries</h4>
          <h5>
            Automatically retry failed debits and give the customer an option to
            change the authorised account.
          </h5>
        </div>
      </mat-card-content>
      <mat-card-actions>
        <button mat-button>Know more</button>
      </mat-card-actions>
    </mat-card>
  </div>
</div>
