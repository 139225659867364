<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="section-title-text" id="businessKycDocuments">
          KYC documents
        </div></mat-panel-title
      >
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>
    <app-business-document
      *ngFor="let doc of documents"
      category="Kyc Details"
      [data]="doc"
    ></app-business-document>
  </mat-expansion-panel>
</mat-accordion>
