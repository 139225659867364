import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-number-field',
  templateUrl: './number-field.component.html'
})
export class NumberFieldComponent {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label: string = "";
  @Input() inputLength: number = 10;
  @Input() isRequired: boolean = false;

  OnInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    let regex: RegExp;
    if (!this.inputLength) {
      regex = /^\d*(\.\d{0,2})?$/;
    } else {
      regex = new RegExp(`^\\d{0,${this.inputLength}}(\\.\\d{0,2})?$`);
    }
  
    if (!regex.test(input.value)) {
      input.value = input.value.slice(0, input.value.length - 1);
      this.dataObj.value = input.value;
    }
  }
  

}
