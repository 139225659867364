<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <div class="expansion-panel-title-container">
                <div class="section-title">Detailed Data Entry</div>
                <div>
                    <button [hidden]="enableEdit" [disabled]="!hasAuthority || !editSections" mat-raised-button *ngIf="panelOpenState"
                        type="button" class="btn btn-primary" data-dismiss="modal" (click)="toggleEditDetails($event)">
                        <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit">Edit</span>
                    </button>
                </div>
                <div class="confirm-buttons" [hidden]="!enableEdit">
                    <button mat-raised-button type="button" class="btn-secondary" data-dismiss="modal"
                        (click)="cancel($event)">
                        <span jhiTranslate="entity.action.cancel">Cancel</span>
                    </button>
                    <button mat-raised-button type="button" class="btn-primary" data-dismiss="modal"
                        (click)="save($event)">
                        <span jhiTranslate="entity.action.save">Save</span>
                    </button>
                </div>
            </div>
        </mat-expansion-panel-header>
        <ng-container *ngIf="houseHoldMap.length > 0">
            <app-dynamic-render-component [title]="'Household Level'" [uiFields]="householdDetailedData"
                [uiFieldsMap]="houseHoldMap" [isSubHeading]="true" [loanId]="loanId" [editSections]="editSections"
                [enableEdit]="enableEdit" [hideEditAction]="true">
            </app-dynamic-render-component>
        </ng-container>
        <hr class="section-hr" />
        <ng-container *ngIf="applicantMap.length > 0">
            <app-dynamic-render-component [title]="'Applicant Level'" [uiFields]="applicantDetailedData"
                [uiFieldsMap]="applicantMap" [isSubHeading]="true" [loanId]="loanId" [editSections]="editSections"
                [enableEdit]="enableEdit" [hideEditAction]="true">
            </app-dynamic-render-component>
        </ng-container>
        <hr class="section-hr" />
        <ng-container *ngIf="memberMap.length > 0 && membersDetailedData.length > 0">
            <div *ngFor="let member of membersDetailedData; let index = index;">
                <app-dynamic-render-component [title]="'Household Member ' + (index + 1)" [uiFields]="member?.fields"
                    [uiFieldsMap]="memberMap" [isSubHeading]="true" [loanId]="loanId" [editSections]="editSections"
                    [enableEdit]="enableEdit" [hideEditAction]="true">
                </app-dynamic-render-component>
                <hr class="section-hr" />
            </div>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>