<mat-accordion>
  <mat-expansion-panel
    [expanded]="panelOpenState"
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
  >
    <mat-expansion-panel-header>
      <div class="expansion-panel-title-container">
        <div class="section-title">Expense</div>
        <div>
          <button
            [hidden]="enableEdit"
            mat-raised-button
            [disabled]="!editSections || !hasAuthority"
            *ngIf="panelOpenState"
            type="button"
            class="btn btn-primary"
            data-dismiss="modal"
            (click)="toggleEditDetails($event)"
          >
            <span class="fa fa-pencil"></span>&nbsp;<span
              jhiTranslate="entity.action.edit"
              >Edit</span
            >
          </button>
        </div>
        <div class="confirm-buttons" [hidden]="!enableEdit">
          <button
            mat-raised-button
            type="button"
            class="btn-secondary"
            data-dismiss="modal"
            (click)="cancel($event)"
          >
            <span jhiTranslate="entity.action.cancel">Cancel</span>
          </button>
          <button
            mat-raised-button
            type="button"
            class="btn-primary"
            data-dismiss="modal"
            (click)="save($event)"
          >
            <span jhiTranslate="entity.action.save">Save</span>
          </button>
        </div>
      </div>
    </mat-expansion-panel-header>
    <ng-container *ngIf="uiFieldsMap.length > 0">
      <app-dynamic-render-component
        title="Household Level"
        [uiFields]="expenseFields"
        [isSubHeading]="true"
        [uiFieldsMap]="uiFieldsMap"
        [loanId]="loanId"
        [editSections]="editSections"
        [enableEdit]="enableEdit"
        [hideEditAction]="true"
      >
      </app-dynamic-render-component>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
