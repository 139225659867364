<ng-http-loader
  [filteredUrlPatterns]="filteredEndpoints"
  spinner="sk-cube-grid"
></ng-http-loader>
<div class="nav-route">
  <router-outlet name="navbar"></router-outlet>
  <router-outlet></router-outlet>
  <router-outlet name="popup"></router-outlet>
</div>
<ig-footer *ngIf="useFooter && checkFooter()"></ig-footer>
