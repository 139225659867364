<div class="row">
  <div class="col-md-5">
    <mat-form-field
      id="userType"
      appearance="fill"
      class="custom-mat-form-field"
    >
      <mat-label>Document belongs to</mat-label>
      <mat-select
        [(value)]="currentOwner"
        (selectionChange)="updateDocs($event.value)"
      >
        <mat-option *ngFor="let user of ownersList" [value]="user"
          >{{ user.viewValue }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col-md-1"></div>
  <div class="col-md-5">
    <mat-form-field
      id="docCategory"
      appearance="fill"
      class="custom-mat-form-field"
    >
      <mat-label>Document Type</mat-label>
      <mat-select
        [(value)]="currentDocument"
        (selectionChange)="recordDoc($event.value)"
      >
        <mat-option *ngFor="let doc of documents" [value]="doc"
          >{{ doc.document }}</mat-option
        >
      </mat-select>
    </mat-form-field>
  </div>
</div>
