<div class="main-container">
    <div class="image-container">
        <img class="image-logo" [src]="logo | async" alt="Logo" />
    </div>
    <div class="profile-section-container">
        <div>
            <app-user-profile-navigation [contentType]="contentType" [userInfo]="userInfo" (changeSection)="changeSection($event)">
            </app-user-profile-navigation>
        </div>
        <div class="profile-info">
            <ng-container [ngSwitch]="contentType">
                <app-user-profile-info [userInfo]="userInfo" *ngSwitchCase="'basicDetails'">
                </app-user-profile-info>
                <app-change-password *ngSwitchCase="'changePassword'" [userInfo]="userInfo">
                </app-change-password>
            </ng-container>
        </div>
    </div>
</div>