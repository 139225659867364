import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoanApplicationService } from "../../../services/krediline/loan-application.service";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";

@Component({
  selector: "jhi-contact-person",
  templateUrl: "./contact-person.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class ContactPersonComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  contactPerson: any;
  initialContactPerson: any;
  public isEditing: boolean;
  @Output() reloadAfterSave = new EventEmitter<any>();

  @Output()
  saveProfileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loanApplicationService: LoanApplicationService,
    private snackBar: MatSnackBar
  ) {
    this.isEditing = false;
    this.saveProfileChange = new EventEmitter();
  }

  ngOnInit() {
    this.contactPerson = { ...this.loanDetails };
    this.initialContactPerson = { ...this.loanDetails };
  }

  public cancel(): void {
    this.isEditing = false;
    this.contactPerson = { ...this.initialContactPerson };
  }

  public edit(): void {
    this.isEditing = true;
  }

  public save(): void {
    this.loanApplicationService.update(this.contactPerson).subscribe(
      (res) => {
        this.isEditing = false;
        this.reloadAfterSave.emit("KCPL fee details");
      },
      (response) => {
        this.snackBar.open("Error in saving record", "Error", {
          duration: 3000,
        });
      }
    );
  }
}
