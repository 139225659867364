import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-account-temporarily-blocked-notify',
  templateUrl: './account-temporarily-blocked-notify.component.html',
})
export class AccountTemporarilyBlockedNotifyComponent {

  constructor(private readonly router: Router) { }

  redirectToResetPassword(): void {
    this.router.navigate(["reset"])
  }

}
