import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { JhiAlertService } from "ng-jhipster";
import { AssociateLenderService } from "../services/associate-lender/associate-lender.service";

@Component({
  selector: "jhi-loan-review-dialog",
  templateUrl: "./kcredit-confirmation-dialog.html",
})
export class LoanApplicationReviewDialogComponent{
  errorText: any = [];
  errorStatus: string;
  noOfEntries: number;
  checkedListWithEligibilityPassed: number;
  checkedListWithEligibilityFailed: number;
  eligibilityFailedForLoanApplication: boolean;
  comment: string = "";
  constructor(
    public activeModal: NgbActiveModal,
    private alertService: JhiAlertService,
    private readonly associateLenderService: AssociateLenderService,
  ) {}


  cancel() {
    this.activeModal.close("cancel");
  }

  approve() {
    this.activeModal.close({
      event: "confirm",
      comment: this.comment
    });
  }


  private onNotifyError(error) {
    try {
      error = error.json();
    } catch (exception) {
      error.message = error.text();
    }
    this.onError(error);
  }

  private onError(error) {
    this.alertService.error(error.error);
  }

  showPDDDocuments() {
    return this.associateLenderService.isPDDDocumentsUpdateEnabled;
  }
}
