import { DatePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from "@angular/core";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-date-field",
  templateUrl: "./date-field.component.html",
})
export class DateFieldComponent implements OnInit, OnChanges {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label: string = "";
  @Input() isRequired: boolean = false;
  @Input() showToolTip: boolean = false;
  @Input() toolTipLabel: string = null;
  @Input() metadata = {};
  @Input() fields = {};
  @Output() triggerUpdate: EventEmitter<any> = new EventEmitter<any>();

  validations: Array<string> = [];
  minDate: Date;
  maxDate: Date;
  initialDate: Date;

  constructor( private readonly datePipe: DatePipe ) { }

  ngOnInit(): void {
    this.initialDate = new Date();
    this.validations = getProperty(this.metadata, "validationType", []);
    this.validations.forEach((validation) =>
      this.updateValidations(validation)
    );
  }

  ngOnChanges(): void {
    this.validations.forEach((validation) =>
      this.updateValidations(validation)
    );
  }
  updateValidations(validation: string = "") {
    let dependableDateKey = "";
    let dependableDateValue = "";
    switch (validation) {
      case "MIN_CURRENT_DATE":
        this.minDate = this.initialDate;
        break;
      case "MAX_CURRENT_DATE":
        this.maxDate = new Date();
        break;
      case "DEPENDABLE_DATES_MAX":
        dependableDateKey = getProperty(this.metadata, "dependableDate", null);
        if (dependableDateKey) {
          dependableDateValue = getProperty(
            this.fields,
            `${dependableDateKey}.value`,
            null
          );
          this.maxDate = new Date(dependableDateValue);
        }
        break;
      case "DEPENDABLE_DATE_RESET":
        dependableDateKey = getProperty(this.metadata, "dependableDate", null);
        if (dependableDateKey) {
          dependableDateValue = getProperty(
            this.fields,
            `${dependableDateKey}.value`,
            null
          );
          const currentValue = new Date(this.dataObj?.value);
          const dependentValue = new Date(
            this.fields[dependableDateKey]?.value
          );

          if (currentValue < dependentValue) {
            this.fields[dependableDateKey].error =
              "Start Date cannot exceed end date";
          }
        }
        break;
      default:
        break;
    }
  }
  edit(date: any = {}, key: string = "", dataObj: any = {}): void {
    if (date["value"]) {
      const newDate: Date = new Date(date["value"]);
      const dateString = this.datePipe.transform(newDate, "yyyy-MM-dd");
      dataObj[key] = dateString;

      this.validations.forEach((validation) =>
        this.updateValidations(validation)
      );
      this.triggerUpdate.emit();
    }
  }

  getDate(dataObj: any = {}): Date | null {
    if (dataObj.value) {
      const date = new Date(dataObj.value);
      return date;
    }
    return null;
  }
}
