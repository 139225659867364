import { Component, Input, OnInit } from "@angular/core";
import { UI_COMPONENTS } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";
import { KcreditLoanDetailsModel } from "../../../loan/kcredit-loanDetails.model";
import { UiConfigService } from "../../../services/ui-config.service";
import { DomSanitizer } from "@angular/platform-browser";

const acceptIcon = "assets/images/common/success-check-circle-outlined.svg";
const rejectIcon = "assets/images/common/mdi_close-circle.svg";
const deviationIcon = "assets/images/common/deviation.svg";

@Component({
  selector: "app-bre-eligibility-calculation",
  templateUrl: "./eligibility-calculation.component.html",
  styleUrls: [
    "./eligibility-calculation.component.scss",
    "../../review-section-fields.scss",
  ],
})
export class BreEligibilityCalculationComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() uiFields: any = {};

  breDecision: string = "--";

  constructor(
    private uiConfigService: UiConfigService,
    private sanitizer: DomSanitizer
  ) {}

  uiFieldsMap = [];
  ngOnInit(): void {
    this.uiConfigService.getUiConfig(UI_COMPONENTS.LOAN_REVIEW).subscribe(
      (response) => {
        const sectionConfig = this.uiConfigService.getUiConfigurationsBySection(
          response,
          "BRE_WITH_ELIGIBILITY_CALCULATIONS",
          true
        );

        this.uiFieldsMap = getProperty(sectionConfig, "uiFieldsMap", []);
        this.breDecision = this.getBreDecision();
      },
      (error) => console.error(error)
    );
  }
  getBreDecision(): string {
    return getProperty(this.uiFields, "breDecision.value", null);
  }

  fetchDecisionIcon(decision = "") {
    let icon = rejectIcon;
    if (decision) {
      switch (decision.toLowerCase()) {
        case "pass":
          icon = acceptIcon;
          break;
        case "fail":
          icon = rejectIcon;
          break;
        default:
          icon = deviationIcon;
      }
    }
    return this.sanitizer.bypassSecurityTrustResourceUrl(icon);
  }
}
