import { Component } from "@angular/core";
import { LoginService, PrincipalService } from "src/app/core";
import { UserInfo } from "src/app/core/auth/UserInfo.constant";

@Component({
  selector: "ig-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.css"],
})
export class LogoutComponent {
  isAuthenticated: boolean = false;
  hidden: boolean = true;
  username: string = "";
  userInfo: UserInfo = {};

  constructor(
    private loginService: LoginService,
    private principalService: PrincipalService
  ) {
    this.isAuthenticated = false;
    this.username = this.principalService.getUserName();
    this.userInfo = this.principalService.getUserInfo();  
  }

  logout() {
    this.loginService.logout();
  }

  navigatePage(route: string, showData: boolean = false, contentType?: string): void {
    const serializedData: string = window.btoa(
      encodeURIComponent(JSON.stringify(this.userInfo))
    );
    const currentUrl: string = window.location.href;
    const baseUrl: string = currentUrl.split("/#/")[0];
    let newUrl: string = `${baseUrl}/#/${route}`;
    if(showData) {
      newUrl = `${newUrl}?data=${serializedData}&contentType=${contentType}`
    }
    window.open(newUrl, "_blank");
  }

  formatDateTime(dateTimeString: string): string {
    const date = new Date(dateTimeString);
    const formattedDate = new Intl.DateTimeFormat('en-US').format(date);
    const hours = date.getHours() % 12 || 12;
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = date.getHours() >= 12 ? 'pm' : 'am';
    const formattedTime = `${hours}:${minutes} ${ampm}`;
    return `${formattedDate} - ${formattedTime}`;
  }

}
