<mat-accordion>
    <mat-expansion-panel [expanded]="panelOpenState" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <div class="expansion-panel-title-container">
                <div class="section-title">Household Details</div>
                <div>
                    <button [hidden]="enableEdit" mat-raised-button [disabled]="!editSections || !hasAuthority" *ngIf="panelOpenState"
                        type="button" class="btn btn-primary" data-dismiss="modal" (click)="toggleEditDetails($event)">
                        <span class="fa fa-pencil"></span>&nbsp;<span jhiTranslate="entity.action.edit">Edit</span>
                    </button>
                </div>
                <div class="confirm-buttons" [hidden]="!enableEdit">
                    <button mat-raised-button type="button" class="btn-secondary" data-dismiss="modal"
                        (click)="cancel($event)">
                        <span jhiTranslate="entity.action.cancel">Cancel</span>
                    </button>
                    <button mat-raised-button type="button" class="btn-primary" data-dismiss="modal"
                        (click)="save($event)">
                        <span jhiTranslate="entity.action.save">Save</span>
                    </button>
                </div>
            </div>
        </mat-expansion-panel-header>
        <ng-container *ngIf="(uiFieldMap).length > 0">
            <ng-container *ngFor="let houseHoldInfo of memberHouseHoldInfo; let index = index">
                <app-household-info [loanId]="loanId" [title]="'Household Member ' + (index + 1)"
                    [uiFields]="houseHoldInfo?.fields" [uiFieldsMap]="uiFieldMap" [hideEditAction]="true"
                    [editSections]="editSections" [enableEdit]="enableEdit">
                </app-household-info>
                <hr class="section-h4">
            </ng-container>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>