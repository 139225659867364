<app-dynamic-render-component
  [title]="title || ''"
  [uiFields]="uiFields"
  [uiFieldsMap]="uiFieldsMap"
  [isSubHeading]="true"
  [loanId]="loanId"
  [editSections]="editSections"
  [enableEdit]="enableEdit"
  [hideEditAction]="hideEditAction"
></app-dynamic-render-component>
