<div class="split-container">
  <!-- Left side (20%) -->
  <mat-card class="left-side">
    <!-- Profile pic - Box shape -->
    <div class="profile-pic">
      <mat-card class="profile-card">
        <img src="assets/images\dashboard/profile.svg" alt="Profile" class="profile-image">
      </mat-card>
      
    </div>
  </mat-card>

  <mat-card class="right-side">
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="Pre processing customer ID"
            [value]="data?.partnerCustomerId || '---'"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="Applicant name"
            [value]="data?.name || '---'"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="KYC POI"
            [value]="data?.poiNumber || data?.idNo || '---'"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="KYC POA"
            [value]="data?.poaNumber || data?.idNo || '---'"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <span matPrefix>&#8377;</span>
          <input
            matInput
            readonly
            placeholder="Loan amount"
            [value]="data?.loanAmount || '---'"
          />
        </mat-form-field>
      </div>
      <div class="col-md-4">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="Created Date &amp; Time"
            [value]="data?.createdDate | date : 'short'"
          />
        </mat-form-field>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <mat-form-field class="input-field">
          <input
            matInput
            readonly
            placeholder="Date of birth"
            [value]="data.dateOfBirth | dedupeCustomDate"
          />
        </mat-form-field>
      </div>
      <div class="col-md-6">
        <mat-form-field class="input-field text-area">
          <textarea
            matInput
            readonly
            placeholder="Address"
            [value]="addressInfo || '---'"
          ></textarea>
        </mat-form-field>
    </div>
  </div>
  </mat-card>
</div>
