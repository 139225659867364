<div>
  <div
    class="username-container"
    [matMenuTriggerFor]="menu"
    disableRipple="true"
  >
    {{ username }}
    <div class="username-container">
      <button mat-icon-button>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </button>
      <div class="avatar">
        <span>{{ getInitials() }}</span>
      </div>
    </div>
  </div>
  <mat-menu #menu="matMenu" yPosition="below" xPosition="after">
    <button mat-menu-item disableRipple="true" (click)="contact()">
      <mat-icon>help_outline</mat-icon> Help
    </button>
    <button mat-menu-item disableRipple="true" (click)="logout()">
      <mat-icon>logout</mat-icon> Logout
    </button>
  </mat-menu>
</div>
