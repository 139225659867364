import { Component, Input, OnChanges } from "@angular/core";
import { CommonReportsDownloadService } from "../../services/common-reports-download.service";
import { AssociateLenderService } from "../../services/associate-lender/associate-lender.service";

@Component({
  selector: "app-common-reports-download",
  templateUrl: "./common-reports-download.component.html",
})
export class CommonReportsDownloadComponent implements OnChanges {
  @Input() fromEntry: boolean = false;
  @Input() loanId: number = null;
  @Input() downloadCams: boolean = false;

  reports: Array<Object> = [
    { viewValue: "ATD Sheet", value: "atd" },
    { viewValue: "FI Report", value: "fiReport" },
  ];

  camsReport: any = { viewValue: "CAM Sheet", value: "cams" };

  constructor(
    private reportService: CommonReportsDownloadService,
    private associateLenderService: AssociateLenderService
  ) {}

  ngOnChanges(): void {
    if (this.downloadCams === true) {
      this.reports.unshift(this.camsReport);
    }
    const lenderCode: string = this.associateLenderService.getLenderCode();
    
    if(lenderCode.toLowerCase() === 'dcbmfi') {
      this.reports = this.reports.filter((report: any)=> {
        return report.value === "cams"
      })
    }
  }

  openReport(reportType: string = "") {
    switch (reportType) {
      case "atd":
        this.reportService.openATDReport(this.loanId, "atd-report");
        break;
      case "cams":
        this.reportService.openCamsSheetReport(this.loanId, "cams-report");
        break;
      case "fiReport":
        this.reportService.openFiReport("fi-report");
        break;
      default:
        break;
    }
  }
}
