import { HttpClient, HttpParams } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { KREDILINE_SERVER_URL } from "src/app/app.constants";
import {
  DEDUPE_MARK_CUSTOMER,
  GET_DEDUPE_LOANS,
  GET_LOANS_BY_CUSTOMER,
} from "src/app/shared/constants/Api.constants";
import { DedupeResponseDTO } from "./dedupe.models";

@Injectable({
  providedIn: "root",
})
export class DedupeService {
  constructor(private http: HttpClient) {}

  fetchDedupeData(
    dedupeReferenceId: number,
    dedupeReferenceType: string = ""
  ): Observable<any> {
    const params = new HttpParams()
      .append("dedupeReferenceId", dedupeReferenceId)
      .append("dedupeReferenceType", dedupeReferenceType);
    return this.http.get(`${KREDILINE_SERVER_URL}${GET_DEDUPE_LOANS}`, {
      params,
    });
  }

  fetchLoansByCustomerNumber(customerNumber: number = null): Observable<any> {
    if (!customerNumber) return new Observable<any>();

    const params = new HttpParams().append("customerId", customerNumber);
    return this.http.get(`${KREDILINE_SERVER_URL}${GET_LOANS_BY_CUSTOMER}`, {
      params,
    });
  }

  markDedupe(
    customerId: number = null,
    dedupeGroupDtoList: Array<DedupeResponseDTO> = [],
    isExistingCustomer: boolean = false
  ): Observable<any> {
    const requestPayload = {
      parentCustomerId: customerId,
      isExistingCustomer,
      dedupeGroupDtoList,
    };
    if (!customerId) return new Observable<any>();
    return this.http.post(
      `${KREDILINE_SERVER_URL}${DEDUPE_MARK_CUSTOMER}`,
      requestPayload,
      { observe: "body", responseType: "text" }
    );
  }
}
