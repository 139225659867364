import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { OAUTH_API_URL } from "src/app/app.constants";
import { LOGOUT } from "src/app/shared/constants/Api.constants";

@Injectable({
  providedIn: "root",
})
export class AuthJwtService {
  constructor(
    private http: HttpClient,
    private $localStorage: LocalStorageService,
    private $sessionStorage: SessionStorageService
  ) {}

  getToken() {
    return this.$localStorage.retrieve("authenticationToken");
  }

  login(credentials: any, authType: string = ""): Observable<any> {
    const data =
      "username=" +
      encodeURIComponent(credentials.username) +
      "&password=" +
      encodeURIComponent(credentials.password) +
      "&grant_type=password&scope=read%20write&" +
      "client_secret=dashboard-secret-token-to-change-in-production&client_id=kaleidodashboardapp";
    const a = {
      "Content-Type": "application/x-www-form-urlencoded",
      Accept: "application/json",
      // pass the auth type with the request authentication_type: authType,
      Authorization:
        "Basic " +
        window.btoa(
          "kaleidodashboardapp" +
            ":" +
            "dashboard-secret-token-to-change-in-production"
        ),
    };
    return this.http
      .post(OAUTH_API_URL + "oauth/token", data, { headers: a })
      .pipe(map(authSuccess.bind(this)));

    function authSuccess(resp: any) {
      const response = resp;
      const expiredAt = new Date();
      expiredAt.setSeconds(expiredAt.getSeconds() + response.expires_in);
      response.expires_at = expiredAt.getTime();
      this.$localStorage.store("authenticationToken", response.access_token);
      localStorage.removeItem("ig-signupuser");
      localStorage.removeItem("signUpUser");
      return response;
    }
  }
  logout(): Promise<any> {
    return this.http.get(LOGOUT).toPromise();
  }

  storeAuthenticationToken(jwt: string, rememberMe: string) {
    if (rememberMe) {
      this.$localStorage.store("authenticationToken", jwt);
    } else {
      this.$sessionStorage.store("authenticationToken", jwt);
    }
  }
}
