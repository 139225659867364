<div class="card-title">
    <form name="partner" novalidate #editForm="ngForm">
        <div class="card-body">
            <div class="card-title">
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.partner" [inputErrors]="editForm.controls.partner?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.partnerInfo.code" for="field_code">Partner Code</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="text" class="form-control" name="code" id="field_code"
                                   [(ngModel)]="partner.code"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="partner.code"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.partner" [inputErrors]="editForm.controls.partner?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.partnerInfo.name" for="field_name">Partner Name</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="text" class="form-control" name="name" id="field_name"
                                   [(ngModel)]="partner.name"/>
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="partner.name"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
            </div>
        </div>
    </form>

