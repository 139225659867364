export class Partners {
  constructor(
    public id?: number,
    public version?: number,
    public name?: string,
    public code?: string,
    public typeOfPartner?: string,
    public isPartnerCustomerIdMandatory?: boolean,
    public cashCollectionMode?: any,
    public computeContributionAmount?: boolean,
    public owner?: boolean,
    public cbCheckRequired?: boolean,
    public defaultResponse?: string,
    public minFirstContributionAmountRequired?: boolean,
    public minFirstContributionAmount?: number,
    public firstAdminFeeInclusive?: boolean,
    public recurringAdminFeeInclusive?: boolean,
    public payDate?: number,
    public customerPaymentMode?: any,
    public helplineNumber?: string,
    public contributionAmountMultiplesOf?: number,
    public paymentDate1?: string,
    public paymentDate2?: string,
    public paymentDates?: string,
    public skipCycle?: string,
    public futureCollectionsRange?: number,
    public minGoalAmount?: number,
    public minGoalTenure?: number,
    public partnerStatus?: string,
    public maxNoOfInvitations?: number,
    public invitationExpiryDays?: number,
    public subDomain?: string,
    public fileId?: number,
    public signzyConfiguration?: number,
    public namePartnerCustomerId?: string,
    public invitationFrequency?: number,
    public lastInvitationSentDate?: any,
    public isPartnerPayEnrollmentFee?: boolean,
    public message?: string,
    public uiFlowConfiguration?: string,
    public nachMaxAmountForDiginach?: number,
    public nachMaxAmountForGoals?: number,
    public typeOfMsaTemplate?: string,
    public numberOfNachForms?: number,
    public kaleidoCreditConfiguration?: string,
    public optionalProperty?: boolean
  ) {}
}
