import { Component, OnInit, Input } from '@angular/core';
import { get } from 'lodash';
import { AUTHORITES } from '../../../constants/authorization.constants';

@Component({
  selector: 'app-post-sanction-docs',
  templateUrl: './post-sanction-docs.component.html',
  styleUrls: ['./post-sanction-docs.component.scss']
})
export class PostSanctionDocsComponent implements OnInit {
  @Input() loanDetailDocuments: any[];
  @Input() partnerId: any;
  @Input() loanId: any;
  panelOpenState:boolean = false;
  documentTypes: any[] = [];
  authority = AUTHORITES.UNDERWRITINGSCREEN_POSTSANCTIONREJECT;
  constructor() {}

  ngOnInit(): void {
    this.documentTypes = Object.keys(this.loanDetailDocuments|| {});
    this.panelOpenState = get(this.documentTypes, "length", 0) > 0;
  }
}
