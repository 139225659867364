<div class="col-lg-11 mandate-container">
  <div class="row">
    <div class="col-md-5 back-button-container" >
      <div class="back-button" [routerLink]="['/paymentMandate']">
        <svg
          width="22"
          height="19"
          viewBox="0 0 22 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M20 9.5H3.5M9 2L1.5 9.5L9 17"
            stroke="#999999"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <h2>Download</h2>
    </div>
  </div>

  <app-download-search-filter></app-download-search-filter>

  <app-download-results></app-download-results>

</div>
