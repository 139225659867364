import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'add-rework-document',
  templateUrl: './add-rework-document.html'
})
export class AddReworkDocumentComponent {

  @Input() docId: number;
  @Input() rework: boolean = false;
  @Input() ownersList: [];
  @Output() documentUpdate = new EventEmitter<{ id: number; entityType: string; documentType: string; entityId: number; loanStage: string; proofType: string }>();

  currentOwner: any;
  currentDocument: any
  entityId: number;
  remarks: string;

  documents = [];

  updateDocs(owner: any) {
    const { entityId } = owner;
    this.documents = owner.docs;
    this.currentOwner = owner;
    this.entityId = entityId;
  }

  recordDoc(document: string) {
    this.currentDocument = document;
    this.emitUpdate();
  }

  emitUpdate() {
    this.documentUpdate.emit({
      id: this.docId,
      entityType: this.currentOwner.value,
      documentType: this.currentDocument.document,
      entityId: this.entityId,
      loanStage: this.currentDocument.stage,
      proofType: this.currentDocument.proofType
    });
  }
}
