<div class="custom">
<button mat-button [matMenuTriggerFor]="list" class="button3">
  <mat-icon>date_range</mat-icon>
  {{ startDate | date:'longDate'}} - {{ endDate | date:'longDate'}}
  <mat-icon>arrow_drop_down</mat-icon>
</button>
<mat-menu #list="matMenu" class="datepicker">
  <button mat-menu-item *ngFor="let option of ranges" (click)="updateDates(option)"> {{ option.view }}</button>
  <button mat-menu-item [matMenuTriggerFor]="custom" yPosition="below">Custom</button>
</mat-menu>
<mat-menu #custom="matMenu" class="datepicker" >
  <div fxLayout="row">
    <mat-calendar [selected]="sD" [maxDate]="todayDate" (selectedChange)="updateStartDate($event)"
    (click)="$event.stopPropagation()" (keypress)="$event.stopPropagation()" tabindex="0"></mat-calendar>
  <mat-calendar [selected]="eD" [minDate]="sD" [maxDate]="todayDate" (selectedChange)="updateEndDate($event)"
    (click)="$event.stopPropagation()" (keypress)="$event.stopPropagation()" tabindex="0"></mat-calendar>
  </div>
  <button mat-button class="cancel" (click)="cancel()">Cancel</button>
  <button mat-button class="apply" (click)="apply()">Apply</button>
</mat-menu>
</div>