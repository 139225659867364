<div>
  <div class="row justify-content-center align-items-center">
    <div class="col-md-12">
      <div>
        <div *ngIf="authenticationError && !partnerMismatch">
          <mat-error> Please check your credentials and try again.</mat-error>
          <br />
        </div>
        <div>
          <form [formGroup]="loginForm" (ngSubmit)="login()" autocomplete="off" novalidate class="clearfix">
            <mat-form-field style="margin-bottom: 0; width: 260px">
              <input matInput type="text" name="username" id="username" placeholder="Username"
                formControlName="username" [errorStateMatcher]="matchers" required />
              <mat-error *ngIf="loginForm.get('username').hasError('required')">
                Please enter your username
              </mat-error>
              <mat-error *ngIf="loginForm.get('username').hasError('pattern')">
                Your username is invalid
              </mat-error>
            </mat-form-field>
            <mat-form-field style="margin-top: 0; width: 260px">
              <input matInput [type]="hide ? 'password' : 'text'" name="password" id="password" placeholder="Password"
                formControlName="password" [errorStateMatcher]="matchers" required />
              <button mat-icon-button matSuffix type="button">
                <mat-icon class="k-password-visibilty" style="font-size: x-large" (click)="
                    hide = !hide;
                    hide ? '' : googleEvent('click', 'Login_show_password')
                  "
                  (keypress)="
                    hide = !hide;
                    hide ? '' : googleEvent('click', 'Login_show_password')
                  " tabindex="0">{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
              </button>
              <mat-error *ngIf="loginForm.get('password').hasError('required') || null">
                Please enter your password
              </mat-error>
            </mat-form-field>
            <div class="forgot-password">
              <a href="javascript:void(0)" class="k-font-10" color="'primary" (click)="resetPassword()">
                <span>Forgot Password?</span>
              </a>
            </div>
            <div class="d-flex">
              <div class="w-100 top-margin">
                <button mat-raised-button class="button1" type="submit" [disabled]="loginForm.invalid"
                  (click)="googleEvent('click', 'Login_continue')">
                  <span>Login</span>
                </button>
              </div>
            </div>
          </form>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>