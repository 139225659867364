import { Component, Input, Output, EventEmitter } from '@angular/core';
import { KcreditLoanDetailsModel } from '../../kcredit-loanDetails.model';
import {CustomerService} from '../../../../customer';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'jhi-dedupe',
  templateUrl: './dedupe.component.html',
  styleUrls: ['../../kcredit-loan.css'],
})
export class DedupeComponent {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  @Input() dedupeData: any;
  public initialData: any;
  public isEditing: boolean;
  
  @Output() reloadAfterSave = new EventEmitter<any>();
  @Output() saveProfileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
      private customerService: CustomerService,
      private snackBar: MatSnackBar
  ) {
    this.isEditing = false;
    this.saveProfileChange = new EventEmitter();
  }

  public cancel(): void {
    this.isEditing = false;
  }

  public edit(): void {
    this.isEditing = true;
  }

  


}
