import { Component, OnInit } from "@angular/core";
import { PrincipalService } from "src/app/core";
import { DashboardService } from "src/app/entities/dashboard/dashboard.service";

@Component({
  selector: "app-overview",
  templateUrl: "./kicredit-overview.component.html",
  styleUrls: ["./kicredit-overview.component.scss"],
})
export class KicreditOverviewComponent implements OnInit {
  overviewItems: Array<any> = [
    {
      icon: "assets/images/overview/business-onboarding.svg",
      label: "Business Onboarding",
      count: 1,
    },
    {
      icon: "assets/images/overview/invoice-review.svg",
      label: "Invoice Review",
      count: 1,
    },
    {
      icon: "assets/images/overview/invoice-agreement.svg",
      label: "Invoice Agreement",
      count: 1,
    },
    {
      icon: "assets/images/overview/disbursal.svg",
      label: "Disbursal",
      count: 1,
    },
    {
      icon: "assets/images/overview/collection.svg",
      label: "Collection",
      count: 1,
    },
  ];

  constructor(
    private readonly principalService: PrincipalService,
    private readonly dashboardService: DashboardService
  ) {}

  ngOnInit(): void {
    this.principalService.identity().then(() => {
      this.dashboardService.sendMessage("shownav");
    });
  }
}
