<div>
    <div class="section-title subtitle caption">
      {{title}}
    </div>
    <table>
      <caption></caption>
      <thead>
        <tr>
          <th *ngFor="let key of getKeys()">
            <div>{{ key }}</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of reportData; let rowIndex = index">
          <td *ngFor="let key of getKeys()" [class]="'row-' + (rowIndex % 2)">
            <div>{{ item[tableMap[key]] }}</div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  