<mat-accordion>
    <mat-expansion-panel>
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="document-type-title">{{AMLDetail.type}}</div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="row row-spacing details-container">
            <div class="col-3">
                <label
                class="form-control-label"
                htmlFor="label-1"
                  >{{nameLabel}}</label
                >
               <div class="label-details">
                {{AMLDetail?.name || "--"}}
               </div>
              </div>
              <div class="col-3">
                <label
                class="form-control-label"
                htmlFor="label-2"
                  >Year Of Birth</label
                >
                <div class="label-details">
                  {{AMLDetail?.yearOfBirth || "--"}}
                </div>
                
              </div>
              <div class="col-3">
                <label
                class="form-control-label"
                htmlFor="label-3"
                  >Match Status</label
                >
                <div [ngClass]="hasMatch && AMLDetail?.matchStatus ? 'match' : 'no-match'">
                  <img
                  *ngIf="AMLDetail?.matchStatus"
                   [src]="fetchDisplayIcon(AMLDetail?.matchStatus)"
                   alt="AMLDetail"
                  >
                  {{AMLDetail?.matchStatus || "--"}}
                </div>
              </div>
        </div>
        <div class="row row-spacing details-container">
            <div class="col-3">
                <label
                class="form-control-label"
                htmlFor="label-4"
                >
                    No. of hits
                </label>
                <div class="label-details">
                  {{AMLDetail?.noOfHits || "--"}}
                </div>
            </div>
            <div 
            *ngIf="AMLDetail?.initiateAml"
            class="col-3">
              <div>
                <button (click)="initiateAml()" mat-flat-button class="aml-button">Initiate AML</button>
              </div>
            </div>
            <div
             *ngIf="showRefreshButton()"
             class="col-3"
            >
            <div>
              <button (click)="refreshAml()" mat-flat-button class="aml-button">Refresh AML</button>
            </div>
            </div>
        </div>
        <ng-container *ngIf="AMLDetail?.amlHits?.length > 0">
          <app-aml-hit-table
            [hits]="AMLDetail?.amlHits"
            [yearOfBirth]="AMLDetail.yearOfBirth"
            [branchCode] = "branchCode"
            (loadAmlDetails) = "loadAmlDetails.emit()"
            [loanApplicationId] = "loanApplicationId"
          >
          </app-aml-hit-table>
        </ng-container>
    </mat-expansion-panel>
</mat-accordion>
