<div class="card-title">
    <form name="contactPerson" novalidate #editForm="ngForm">
        <div class="card-body">
            <div class="card-title">
                <div class="row">
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.contactPerson" [inputErrors]="editForm.controls.contactPerson?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.contactPerson.fullName" for="field_full_name">Full Name</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="full_name" id="field_full_name"
                                   [(ngModel)]="contactPerson.fullName" />
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="contactPerson.fullName"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.contactPerson" [inputErrors]="editForm.controls.contactPerson?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.contactPerson.phoneNo" for="field_phone_no">Phone No</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="phone_no" id="field_phone_no"
                                   [(ngModel)]="contactPerson.phoneNo" />
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="contactPerson.phoneNo"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.contactPerson" [inputErrors]="editForm.controls.contactPerson?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.contactPerson.mobileNo" for="field_mobile_no">Mobile No</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="mobile_no" id="field_mobile_no"
                                   [(ngModel)]="contactPerson.mobileNo" />
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="contactPerson.mobileNo"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                    <div class="form-group col-md-3">
                        <ig-input  [inputField]="editForm.controls.contactPerson" [inputErrors]="editForm.controls.contactPerson?.errors">
                            <label class="form-control-label" jhiTranslate="kCredit.contactPerson.emailId" for="field_email_id">Email Id</label>
                            <input [hidden]="!isEditing" [disabled]="!isEditing" type="number" class="form-control" name="email_id" id="field_email_id"
                                   [(ngModel)]="contactPerson.emailId" />
                            <div [hidden]="isEditing">
                                <jhi-null-replace [value]="contactPerson.emailId"></jhi-null-replace>
                            </div>
                        </ig-input>
                    </div>
                </div>
            </div>
        </div>
    </form>

