
<app-dynamic-render-component
   [title]="'Applicant Details'"
   [uiFields]="uiFields"
   [uiFieldsMap]="uiFieldsMap"
   [isSubHeading]="true"
   [loanId]="loanDetails.loanApplicationDTO.id"
   [uiApiKey]="uiApiKey"
   [editSections]="editSections"
   [hideEditAction]="!showEditButton"
   [payloadTypeKey]="'applicant'"
  [payloadType]="'object'"
>
</app-dynamic-render-component>