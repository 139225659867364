import { Routes } from '@angular/router';
import { ForgotPasswordComponent } from 'src/app/account/forgot-password/forgot-password.component';
import { ContactComponent } from './contact/contact.component';
import { DiscoverComponent } from './discover/discover.component';
import { PaymentMandateComponent } from '../payment-mandate/payment-mandate/payment-mandate.component';

export const DASHBOARD: Routes = [
  {
    path: 'dashboard/home',
    redirectTo: "nachForms/prefilledNachForms"
    // component: DashboardComponent,
  },
  {
    path:'paymentMandate',
    component: PaymentMandateComponent
  },
  {
    path: 'contact',
    component: ContactComponent,
    
  },
  {
    path: 'reset',
    component: ForgotPasswordComponent,
    
  },
  {
    path: 'discover',
    component: DiscoverComponent,
  },
];
