export enum AddressType {
  CURRENT,
  PERMANENT,
  BUSINESS,
  REFERENCE,
  NOMINEE,
  NOMINEE_GAURDIAN,
  GUARANTOR,
  WITNESS,
  CO_APPLICANT,
}
