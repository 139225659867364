<mat-form-field *ngIf="!formControl; else numberWithFormControl" appearance="outline" [matTooltip]="toolTipLabel"
  [matTooltipDisabled]="!showToolTip" matTooltipPosition="above">
  <mat-label>{{ label }}</mat-label>
  <input matInput [id]="label" type="text" [(ngModel)]="dataObj.value" [disabled]="!editable" [required]="isRequired"
    (input)="OnInput($event)" />
</mat-form-field>

<ng-template #numberWithFormControl>
  <mat-form-field appearance="outline" [matTooltip]="toolTipLabel" [matTooltipDisabled]="!showToolTip"
    matTooltipPosition="above">
    <mat-label>{{ label }}</mat-label>
    <input matInput [id]="label" type="text" [formControl]="formControl" [disabled]="!editable" [required]="isRequired"
      (input)="OnInput($event)" />
  </mat-form-field>
</ng-template>