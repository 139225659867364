<div class="password-container">
  <div class="tooltip-container">
    <label [for]="title">{{ title }}</label>
    <img
      src="assets/images/login/landing_tooltip_image.svg"
      alt="ToolTip"
      mat-button
      [matMenuTriggerFor]="passwordGuidelinesMenu"
      class="info-icon"
      onKeyPress=""
      *ngIf="showGuidelines"
    />
  </div>

  <mat-menu
    #passwordGuidelinesMenu="matMenu"
    class="custom-menu"
    [overlapTrigger]="false"
    [yPosition]="'below'"
    [xPosition]="'after'"
  >
    <div class="menu-content">
      <div class="menu-header">
        <span><b>Password Guidelines</b></span>
        <button mat-icon-button (click)="$event.stopPropagation(); closeMenu()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <ol>
        <li>
          Passwords must contain a combination of characters such as uppercase
          letters, lowercase letters, numbers, and special characters. ex:
          Kaleidofin_&#64;tarmani20
        </li>
        <li>Passwords must be at least 6 characters long.</li>
        <li>It should not be your last 3 passwords.</li>
      </ol>
    </div>
    <div class="tooltip-arrow"></div>
  </mat-menu>

  <div class="input-container" [formGroup]="myForm">
    <input
      [type]="passwordFieldType"
      required
      [placeholder]="placeHolder"
      [formControlName]="id"
      [ngClass]="{ 'error-input': showError || (showPasswordError && isCurrentPassword) }"
    />
    <img
      class="image-1"
      [src]="
        passwordFieldType === 'password'
          ? 'assets/images/login/landing_eye_image.svg'
          : 'assets/images/login/landing_eye_off_image.svg'
      "
      alt="Toggle Password Visibility"
      (click)="togglePasswordVisibility()"
      onKeyPress=""
    />
  </div>
  <span *ngIf="showError && errorMessage" class="error-message">{{
    errorMessage
  }}</span>
    <span *ngIf="isCurrentPassword && showPasswordError && errorMessage" class="error-message">{{
      errorMessage
    }}</span>
    <ng-container *ngIf="remainingRequirements.length > 0 && showRequirements">
      <ul>
        <li class="error-message" *ngFor="let error of remainingRequirements">
          <span class="error-message">
            {{error}}
          </span>
      </li>
      </ul>
    </ng-container>
</div>
