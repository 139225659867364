import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { getProperty } from "src/app/utils/app.utils";
import { AssociateLenderService } from "../../services/associate-lender/associate-lender.service";
import { LENDER_CONFIGURATIONS } from "src/app/constants/lender.config";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.component.html",
  styleUrls: ["./landing.component.scss"],
})
export class LandingComponent implements OnInit {
  currentPage: string = "Login";
  metaData: any = {};
  constructor(
    private readonly route: ActivatedRoute,
    private readonly associateLenderService: AssociateLenderService
  ) {}

  ngOnInit(): void {
    const snapshotMetadata = this.route.snapshot.data["metaData"];
    if (snapshotMetadata) {
      this.associateLenderService.setLenderCode(
        snapshotMetadata?.lenderCode ?? "KCPL"
      );
    }
    const lender = this.associateLenderService.getLenderCode();
    if (lender?.length) {
      this.metaData = LENDER_CONFIGURATIONS[lender];
    }
    const page: string = getProperty(
      this.route.snapshot.data,
      "currentPage",
      ""
    );
    this.updatePage(page);
  }

  updatePage(event: string) {
    this.currentPage = event;
  }
}
