<div>
  <div class="row">
    <div class="col-md-2">
      <button class="btn btn-primary" mat-raised-button (click)="markDedupe()" [disabled]="disableMarkingNewCustomer">
        Mark as new Customer
      </button>
    </div>
    <div class="col-md-3" *ngIf="tableItems.length">
      <button
        [disabled]="!selectedItems.length"
        class="btn btn-primary"
        mat-raised-button
        (click)="markDedupe(true)"
      >
        Mark as existing Customer
      </button>
    </div>
  </div>
</div>
<div *ngIf="tableItems.length > 0; else noloans">
  <table>
  <caption></caption>
    <thead>
      <tr>
        <th class="small-width"></th>
        <th>Applicant Name</th>
        <th>Partner ID</th>
        <th>Loan ID</th>
        <th>Application Date</th>
        <th>Duplicate Parameter type</th>
        <th>Product Code</th>
        <th>Link</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of tableItems">
        <td class="small-width">
          <mat-checkbox (change)="selectItem(item)"></mat-checkbox>
        </td>
        <td>{{ item.name }}</td>
        <td>{{ item.partnerId }}</td>
        <td>{{ item.loanNumber }}</td>
        <td>{{ item.createdDate }}</td>
        <td>{{ item.dedupeParameter }}</td>
        <td>{{ item.productCode }}</td>
        <td>
          <button mat-button class="btn btn-primary" (click)="openLoan(item)">
            Open Link
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #noloans>
  <hr />
  <h3 class="text-center kcredit-failure-color">No Dedupes found</h3>
</ng-template>
