<div>
    <div *ngFor="let number of docsArray" >
      <div *ngIf="number"  class="doc-container">
      <add-rework-document
        [docId]="number"
        [ownersList]="userTypeList"
        rework="rework"
        (documentUpdate)="onDocumentUpdate($event)">
      </add-rework-document>
      <button (click)="removeDocument(number)" class="icon-button">
        <img src="assets/images/common/delete.svg" alt="Add Document">
      </button>
      <button (click)="addDocument()" class="icon-button">
        <img src="assets/images/common/add.svg" alt="Add Document">
      </button>
    </div>
  </div>
</div>