<div class="col-lg-11 mandate-container">
  <div class="row">
    <div class="mandate-title-container">
      <div class="col-md-5">
        <h2>SBM SI Mandate</h2>
      </div>
      <div class="action-buttons">
        <div class="button-group">
          <button
            *ngIf="!disableDownloadBtn"
            mat-button
            class="button2 download-button"
            [routerLink]="['/paymentMandate/download']"
          >
            Download
          </button>
          <button
            *ngIf="!disableSearch"
            mat-button
            class="button3"
            (click)="onSearchClick()"
          >
            <mat-icon>search</mat-icon>
          </button>

          <mat-form-field
            appearance="none"
            class="select-boxes"
            *ngIf="disableSearch"
          >
            <mat-label>Search by</mat-label>
            <mat-select
              placeholder="Click to select"
              (selectionChange)="onSearchEntityChange($event)"
              [(ngModel)]="selectedSearchEntity"
            >
              <mat-option
                *ngFor="let searchEntity of searchEntities"
                [value]="searchEntity.value"
              >
                {{ searchEntity.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field
            *ngIf="disableSearch"
            appearance="none"
            class="search-textfield"
          >
            <input
              matInput
              placeholder="Search By {{ searchPlaceholder }}"
              [(ngModel)]="searchText"
              (keyup.enter)="searchMandate()"
            />
            <mat-icon matSuffix (click)="onSearchCloseBtn()" (keypress)="onSearchCloseBtn()" tabindex="0">close</mat-icon>
          </mat-form-field>

          <button
            mat-button
            class="button3"
            (click)="updateDateRange()"
            [class]="openDateRangeComponent ? 'opened' : 'closed'"
          >
            <mat-icon>date_range</mat-icon>
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="openDateRangeComponent">
      <div class="date-range-container">
        <app-mandate-date-filter
          (onSearch)="searchMandate($event)"
        ></app-mandate-date-filter>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="mandate-table-container">
      <table mat-table [dataSource]="dataSource" matSort>
        <ng-container matColumnDef="customerName">
          <th
            mat-header-cell
            *matHeaderCellDef
            mat-sort-header
            class="name-column"
          >
            CUSTOMER NAME & MOBILE NUMBER
          </th>
          <td mat-cell *matCellDef="let user">
            {{ user.customerName }}
            <div class="sub-text">{{ user.mobileNo }}</div>
          </td>
        </ng-container>

        <ng-container matColumnDef="startDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>START DATE</th>
          <td mat-cell *matCellDef="let user">{{ user.startDate }}</td>
        </ng-container>

        <ng-container matColumnDef="endDate">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>END DATE</th>
          <td mat-cell *matCellDef="let user">{{ user.endDate }}</td>
        </ng-container>

        <ng-container matColumnDef="loanId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>LOAN ID</th>
          <td mat-cell *matCellDef="let user">{{ user.loanId }}</td>
        </ng-container>
        <ng-container matColumnDef="emiAmount">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>EMI AMOUNT</th>
          <td mat-cell *matCellDef="let user">
            &#8377;&nbsp;{{ user.amount }}
          </td>
        </ng-container>
        <ng-container matColumnDef="frequency">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>FREQUENCY</th>
          <td mat-cell *matCellDef="let user">{{ user.frequency }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
          <td mat-cell *matCellDef="let user" [class]="user.cssClass">
            {{ user.statusLabel }}
          </td>
        </ng-container>
        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef>ACTION</th>
          <td mat-cell *matCellDef="let user">
            <button
              mat-raised-button
              class="button4 view-button"
              (click)="openMandate(user)"
            >
              View
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns; let i = index"
          class="row-even"
        ></tr>
      </table>

      <mat-paginator
        *ngIf="resultsLength !== 0"
        [pageSize]="pageSize"
        [length]="resultsLength"
        [showFirstLastButtons]="true"
        [pageIndex]="currentPage"
        (page)="onPageChange($event)"
        showPageSizeOptions
      ></mat-paginator>
    </div>
  </div>
</div>

<ng-container *ngIf="resultsLength === 0">
  <div class="no-downloads">
    <mat-icon>save_alt</mat-icon>
    No Mandates
  </div>
</ng-container>
