<div class="document-subtype-container">
  <div>{{ subtype ? "Document Type - " + subtype : "" }}</div>
  <div class="document-action-button-container">
    <button
      mat-raised-button
      class="btn-secondary"
      [hidden]="!checkForTags()"
      (click)="tagAllDocuments()"
      [disabled]="!disableEditAccess"
    >
      Tag All
    </button>
    <button
      mat-raised-button
      class="btn-secondary reject-button"
      *ngIf="hideRejectAllSection()"
      [disabled]="
        !disableEditAccess ||
        disableButtonsForTags ||
        validateRejectAll() ||
        !isApplicationAssigned
      "
      (click)="acceptRejectAllDocuments('REJECT')"
    >
      Reject All
    </button>
    <button
      mat-raised-button
      class="btn-primary"
      *ngIf="hideRejectAllSection()"
      [disabled]="
        !disableEditAccess ||
        disableButtonsForTags ||
        validateRejectAll() ||
        !isApplicationAssigned
      "
      (click)="acceptRejectAllDocuments('ACCEPT')"
    >
      Accept All
    </button>
  </div>
</div>
<div class="document-container">
  <div class="item" *ngFor="let doc of documents">
    <div *ngIf="doc.fileId || doc.documentFileId">
      <app-doc-title-container
        [doc]="doc"
        [documentCategory]="documentCategory"
        [id]="loanId"
        [disableEditButtons]="checkForTags()"
        (editImage)="editImage(doc, 'CROP')"
        [hideEditButtons]="checkAndDisableActions(doc)"
        [docType]="doc?.docType"
        [isApplicationAssigned]="isApplicationAssigned"
      ></app-doc-title-container>
      <br />
      <div class="image-container" *ngIf="doc.image">
        <img
          *ngIf="!isPdf(doc)"
          class="d-inline-block nach-image fill-image"
          [src]="doc.image"
          alt="Doc"
        />
        <img
          *ngIf="isPdf(doc)"
          class="d-inline-block nach-image fill-image"
          [src]="doc.fileIcon"
          [alt]="doc.documentName"
        />
      </div>
      <br />
      {{ doc?.documentName }} &nbsp; &#x2022; &nbsp;
      <span [class]="doc?.docSizeClass">{{ doc?.size }}</span>
      <div class="document-action-container">
        <button
          mat-raised-button
          class="btn-secondary reject-button"
          (click)="acceptRejectDocument(doc, 'REJECT')"
          [hidden]="checkAndDisableActions(doc)"
          [disabled]="
            !disableEditAccess ||
            disableButtonsForTags ||
            checkForTags() ||
            !isApplicationAssigned
          "
        >
          Reject
        </button>
        <button
          mat-raised-button
          class="btn-primary"
          (click)="acceptRejectDocument(doc, 'ACCEPT')"
          [hidden]="checkAndDisableActions(doc)"
          [disabled]="
            !disableEditAccess ||
            disableButtonsForTags ||
            checkForTags() ||
            !isApplicationAssigned
          "
        >
          Accept
        </button>
      </div>
      <div class="document-subtype-container">
        <document-review-status-alert
          [reviewStatus]="doc?.reviewStatus || doc?.verificationStatus"
          [rejectReasons]="doc?.reviewRemarks || doc?.message"
        ></document-review-status-alert>
      </div>
    </div>
  </div>
</div>
