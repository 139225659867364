import { Route } from "@angular/router";
import { LENDER_CONFIGURATIONS } from "../constants/lender.config";
import { LandingComponent } from "../entities/kaleido-credit/organisms/landing/landing.component";

export const HOME_ROUTE: Route = {
  path: "",
  component: LandingComponent,
  data: {
    authorities: [],
    metaData: LENDER_CONFIGURATIONS.KCPL,
    pageTitle: "Kaleidofin",
  },
};

export const USER_LOCKOUT: Route = {
  path: "lockout",
  component: LandingComponent,
  data: {
    authorities: [],
    pageTitle: "User Lockout",
    currentPage: "Account Temporarily Locked Notification",
  },
};

export const RESET_PASSWORD: Route = {
  path: "expired",
  component: LandingComponent,
  data: {
    authorities: [],
    pageTitle: "Kaleidofin",
    currentPage: "Set New Password Notification",
  },
};

export const DCB_ROUTE: Route = {
  path: "DCB",
  component: LandingComponent,
  data: {
    authorities: [],
    pageTitle: "DCB",
    metaData: LENDER_CONFIGURATIONS.DCB,
  },
};

