<mat-form-field appearance="outline">
  <mat-label>{{ label }}</mat-label>
  <input 
    matInput 
    [formControl]="inputFormControl"
    [maxlength]="inputLength" 
    [required]="isRequired"
  />
</mat-form-field>
<mat-error *ngIf="inputFormControl.errors?.['invalidPattern']">
  {{errorMessage}}
</mat-error>
