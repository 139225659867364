export const AUTHORITES = {
  OVERVIEW_VIEW: "OVERVIEW_VIEW",
  LOANENTRY_VIEW: "LOANENTRY_VIEW",
  LOANENTRY_SEARCH: "LOANENTRY_SEARCH",
  LOANENTRY_ACTIONVIEW: "LOANENTRY_ACTIONVIEW",
  LOANENTRY_REASONSINCOMPLETE: "LOANENTRY_REASONSINCOMPLETE",
  LOANENTRY_COPYREASONSINCOMPLETE: "LOANENTRY_COPYREASONSINCOMPLETE",
  LOANENTRY_UNDERWRITINGSCREEN_VIEWAPPANDDOCS:
    "LOANENTRY_UNDERWRITINGSCREEN_VIEWAPPANDDOCS",
  LOANENTRY_UNDERWRITINGSCREEN_COMMENT: "LOANENTRY_UNDERWRITINGSCREEN_COMMENT",
  LOANENTRY_UNDERWRITINGSCREEN_REASONSINCOMPLETE:
    "LOANENTRY_UNDERWRITINGSCREEN_REASONSINCOMPLETE",
  LOANENTRY_UNDERWRITINGSCREEN_COPYREASONSINCOMPLETE:
    "LOANENTRY_UNDERWRITINGSCREEN_COPYREASONSINCOMPLETE",
  LOANENTRY_UNDERWRITINGSCREEN_VIEWCOMMENTSHISTORY:
    "LOANENTRY_UNDERWRITINGSCREEN_VIEWCOMMENTSHISTORY",
  LOANREVIEW_VIEW: "LOANREVIEW_VIEW",
  LOANREVIEW_SEARCH: "LOANREVIEW_SEARCH",
  LOANREVIEW_ACTIONREVIEW: "LOANREVIEW_ACTIONREVIEW",
  LOANREVIEW_UNDERWRITINGSCREEN_EDITSCREENDETAILS:
    "LOANREVIEW_UNDERWRITINGSCREEN_EDITSCREENDETAILS",
  DOWNLOADLOANAPPDOCS: "DOWNLOADLOANAPPDOCS",
  UPLOADLOANREVIEWREPORT: "UPLOADLOANREVIEWREPORT",
  UNDERWRITINGSCREEN_VIEWAPPANDDOCS: "US_VIEWAPPANDDOCS",
  UNDERWRITINGSCREEN_EDITSCREENDETAILS: "US_EDITSCREENDETAILS",
  UNDERWRITINGSCREEN_KYCCONDITIONALLYREJECT: "US_KYCCONDITIONALLYREJECT",
  UNDERWRITINGSCREEN_PRESANCTIONCONDITIONALLYREJECT:
    "US_PRESANCTIONCONDITIONALLYREJECT",
  UNDERWRITINGSCREEN_POSTSANCTIONREJECT: "US_POSTSANCTIONREJECT",
  UNDERWRITINGSCREEN_POSTDISBURSEMENTREJECT: "US_POSTDISBURSEMENTREJECT",
  UNDERWRITINGSCREEN_UPLOADDOCUMENTS: "US_UPLOADDOCUMENTS",
  UNDERWRITINGSCREEN_VIEWBREREPORT: "US_VIEWBREREPORT",
  UNDERWRITINGSCREEN_VIEWCIBILREPORTS: "US_VIEWCIBILREPORTS",
  UNDERWRITINGSCREEN_RECALCULATEBRE: "US_RECALCULATEBRE",
  UNDERWRITINGSCREEN_VIEWDEMANDSCHEDULE: "US_VIEWDEMANDSCHEDULE",
  UNDERWRITINGSCREEN_UPLOADRCUREPORT: "US_UPLOADRCUREPORT",
  UNDERWRITINGSCREEN_COMMENT: "US_COMMENT",
  UNDERWRITINGSCREEN_APPROVEMAKERS: "US_APPROVEMAKERS",
  UNDERWRITINGSCREEN_CONDITIONALREJECTMAKERS: "US_CONDITIONALREJECTMAKERS",
  UNDERWRITINGSCREEN_FINALAPPROVECHECKER: "US_FINALAPPROVECHECKER",
  UNDERWRITINGSCREEN_CONDITIONALREJECTCHECKER: "US_CONDITIONALREJECTCHECKER",
  UNDERWRITINGSCREEN_FINALREJECTCHECKER: "US_FINALREJECTCHECKER",
  UNDERWRITINGSCREEN_ASSIGNAPPLICATION: "US_ASSIGNAPPLICATION",
  UNDERWRITINGSCREEN_VIEWCOMMENTSHISTORY: "US_VIEWCOMMENTSHISTORY",
  UNDERWRITINGSCREEN_DOWNLOADCAMOTHERS: "US_DOWNLOADCAMOTHERS",
  UNDERWRITINGSCREEN_DOWNLOADLOANAPPDOCS: "US_DOWNLOADLOANAPPDOCS",
  LOANAGREEMENT_VIEW: "LOANAGREEMENT_VIEW",
  LOANAGREEMENT_SEARCH: "LOANAGREEMENT_SEARCH",
  LOANAGREEMENT_ACTIONREVIEW: "LOANAGREEMENT_ACTIONREVIEW",
  LOANAGREEMENT_DOWNLOADLOANAPPDOCS: "LOANAGREEMENT_DOWNLOADLOANAPPDOCS",
  LOANAGREEMENT_UPLOADLOANREVIEWREPORT: "LOANAGREEMENT_UPLOADLOANREVIEWREPORT",

  LOANBOOKING_VIEW: "LOANBOOKING_VIEW",
  LOANBOOKING_SEARCH: "LOANBOOKING_SEARCH",
  LOANBOOKING_ACTIONREVIEW: "LOANBOOKING_ACTIONREVIEW",
  LOANBOOKING_DOWNLOADLOANAPPDOCS: "LOANBOOKING_DOWNLOADLOANAPPDOCS",
  LOANDISBURSAL_VIEW: "LOANDISBURSAL_VIEW",
  LOANDISBURSAL_SEARCH: "LOANDISBURSAL_SEARCH",
  LOANDISBURSAL_ACTIONREVIEW: "LOANDISBURSAL_ACTIONREVIEW",
  LOANDISBURSAL_DOWNLOADLOANAPPDOCS: "LOANDISBURSAL_DOWNLOADLOANAPPDOCS",
  LOANDISBURSAL_UPLOADLOANDISBURSEMENTREPORT:
    "LOANDISBURSAL_UPLOADLOANDISBURSEMENTREPORT",
  GET_LOAN_REVIEW_INFO: "GET_LOAN_REVIEW_INFO",
  APPROVE_DEVIATIONS: "APPROVE_DEVIATIONS",
  EDIT_DETAILS: "EDIT_DETAILS",
  APPROVE_REJECT_LOANS: "APPROVE_REJECT_LOANS",
};
