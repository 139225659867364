import { Injectable } from "@angular/core";
import * as jspdf from "jspdf";
import html2canvas from "html2canvas";

@Injectable({
  providedIn: "root",
})
export class PdfExportService {
  constructor() {}

  async downloadCurrentPageAsPdf(pdfName?: string) {
    const filename = pdfName ? `${pdfName}.pdf` : "report.pdf";

    const downloadButton = document.getElementById("pdf-download-button");
    if (downloadButton) {
      downloadButton.style.display = "none";
    }

    try {
      const element = document.documentElement;
      const maxWidth = document.body.scrollWidth;
      const maxHeight = document.body.scrollHeight;

      const imageQuality = 0.8;
      const pdf = new jspdf.jsPDF("p", "mm", [maxWidth, maxHeight], false);

      const canvas = await html2canvas(element, {
        width: maxWidth,
        height: maxHeight,
        useCORS: true,
        logging: false,
      });
      const imgData = canvas.toDataURL("image/jpeg", imageQuality);

      const aspectRatio = canvas.width / canvas.height;
      const adjustedHeight = maxWidth / aspectRatio;

      pdf.addImage(imgData, "JPEG", 0, 0, maxWidth, adjustedHeight);

      pdf.save(filename);
    } catch (error) {
      console.error("Error generating PDF:", error);
    } finally {
      if (downloadButton) {
        downloadButton.style.display = "block";
      }
    }
  }
}
