<div class="success-container">
    <div class="header-container">
        <span> Status </span>
        <button class="close-button" (click)="close()"><mat-icon>close</mat-icon></button>
    </div>
    <div class="message-container">
        <div class="message">
            <img src="assets/images/common/upload-success.svg" alt="success">
            <span>File successfully uploaded</span>
        </div>
            <button mat-flat-button class="confirm-button" (click)="close()">Ok</button>
    </div>
</div>