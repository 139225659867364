import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { AuthJwtService } from "../auth/auth-jwt.service";
import { PrincipalService } from "../auth/principal.service";
import { AssociateLenderService } from "src/app/entities/kaleido-credit/services/associate-lender/associate-lender.service";
import { LENDER_CONFIGURATIONS } from "src/app/constants/lender.config";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private principal: PrincipalService,
    private authServerProvider: AuthJwtService,
    private readonly associateLenderService: AssociateLenderService,
    private router: Router,
    private snackbar: MatSnackBar
  ) {}

  login(credentials: any, authType: any, callback?: any) {
    const cb = callback || function () {};
    return new Promise((resolve, reject) => {
      this.authServerProvider.login(credentials, authType).subscribe(
        (data) => {
          this.principal
            .identity(true)
            .then(() => {
              this.snackbar.open("Logged in successfully!", "", {
                duration: 3000,
              });
              resolve(data);
            })
            .catch((err) => {
              reject(new Error(err));
            });
          return cb();
        },
        (err) => {
          this.logout();
          reject(new Error(err));
          return cb(err);
        }
      );
    });
  }

  logout() {
    this.authServerProvider.logout().then(() => {
      this.principal.authenticate(null);
      localStorage.clear();
      let logoutRoute = "/";
      let lenderCode = this.associateLenderService.getLenderCode();
      if (lenderCode) {
        let { defaultLogoutRoute = "/" } = LENDER_CONFIGURATIONS[lenderCode];
        logoutRoute = defaultLogoutRoute;
      }
      this.router.navigateByUrl(logoutRoute);
    });
  }
}
