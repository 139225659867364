
<app-form
	[header]="'Set new Password'"
	[myForm]="myForm"
	[formFields]="currentForm"
	[buttonTitle]="'Submit'"
	[forgetPassword]="''"
	[showPasswordError]="showPasswordError"
	[remainingRequirements]="remainingRequirements"
></app-form>

<div class="setNewPassword-container">
	<app-login-page-button [title]="'Submit'" [disabled]="myForm.invalid" (onButtonClick)="onSubmit()"></app-login-page-button>
	<span class="or-span">--or--</span>
	<button (click)="resetPasswordViaMail()" class="setNewPassword-link">Reset password via email</button>	
</div>
