import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { LoanFee } from "../../../models/loan-fee.model";
import { LoanApplicationService } from "../../../services/krediline/loan-application.service";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";

@Component({
  selector: "jhi-kcpl-fee-details",
  templateUrl: "./kcpl-fee-details.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class KcplFeeDetailsComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  loanFee: LoanFee;
  initialLoanFee: LoanFee;
  public isEditing: boolean;
  @Output() reloadAfterSave = new EventEmitter<any>();

  @Output()
  saveProfileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loanApplicationService: LoanApplicationService,
    private snackBar: MatSnackBar
  ) {
    this.isEditing = false;
    this.saveProfileChange = new EventEmitter();
  }

  ngOnInit() {
    this.loanFee = { ...this.loanDetails.loanFeeDTO };
    this.initialLoanFee = { ...this.loanDetails.loanFeeDTO };
  }

  public cancel(): void {
    this.isEditing = false;
    this.loanFee = { ...this.initialLoanFee };
  }

  public edit(): void {
    this.isEditing = true;
  }

  public save(): void {
    this.loanApplicationService.update(this.loanFee).subscribe(
      (res) => {
        this.isEditing = false;
        this.reloadAfterSave.emit("KCPL fee details");
      },
      (response) => {
        this.snackBar.open("Error in saving record", "Error", {
          duration: 3000,
        });
      }
    );
  }
}
