<div class="main-container">
  <div fxLayout="row wrap" style="justify-content: space-between;">
    <div>
      <h1>Partner Loan Demand</h1>
      <h5>View upload history and import new demands</h5>
    </div>
    <button mat-raised-button class="button2" (click)="navigateToUpload()"><img
        src="assets/images/upload.svg" alt="Upload"> Upload new demands</button>
  </div>
  <div>
    <table mat-table [dataSource]="data" matSort (matSortChange)="sortData()" id="listtable">
    <caption></caption>
      <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DATE</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{row.createdDate | date:'dd MMM yyyy'}}</h4>
          <h5>{{row.createdDate | date:'mediumTime'}}</h5>
        </td>
      </ng-container>

      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TASK ID</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{row.id}}</h4>
        </td>
      </ng-container>

      <ng-container matColumnDef="category">
        <th mat-header-cell *matHeaderCellDef>TASK CATEGORY</th>
        <td mat-cell *matCellDef="let row">
          <h4 style="color: #2157A8">{{row.jobName}}</h4>
          <h5>{{row.inputFileId}}</h5>
        </td>
      </ng-container>

      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef>TOTAL</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{row.totalCount}}</h4>
        </td>
      </ng-container>

      <ng-container matColumnDef="success">
        <th mat-header-cell *matHeaderCellDef>SUCCESS</th>
        <td mat-cell *matCellDef="let row">
          <h4>{{row.successCount}}</h4>
        </td>
      </ng-container>

      <ng-container matColumnDef="failure">
        <th mat-header-cell *matHeaderCellDef>FAILURE</th>
        <td mat-cell *matCellDef="let row">
          <h4 style="color: #EB5757">{{row.failureCount}}</h4>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>STATUS</th>
        <td mat-cell *matCellDef="let row">
          <div class="success" *ngIf="row.jobStatus=='Success' || row.jobStatus=='Processed'">{{row.jobStatus}}</div>
          <div class="failure" *ngIf="row.jobStatus=='Failed' || row.jobStatus=='Failure'">{{row.jobStatus}}</div>
          <div class="cancel" *ngIf="row.jobStatus=='Cancelled'">{{row.jobStatus}}</div>
          <div class="other" *ngIf="row.jobStatus!='Success' && row.jobStatus!='Processed' && row.jobStatus!='Failed' && row.jobStatus!='Failure' && row.jobStatus!='Cancelled'" >{{row.jobStatus}}</div>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-paginator [pageSize]="pageSize" [length]="resultsLength" [showFirstLastButtons]="true" [pageIndex]="currentPage"
    (page)="handlePage($event)"></mat-paginator>
</div>