import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { AssociateLenderService } from "../../entities/kaleido-credit/services/associate-lender/associate-lender.service";

@Injectable()
export class SingleTenancyInterceptor implements HttpInterceptor {
  constructor(private associateLenderService: AssociateLenderService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (request.url.includes("krediline-server")) {
      let lender = this.associateLenderService.getLenderCode();
      request = request.clone({
        setHeaders: {
          lenderCode: lender,
        },
      });
    }
    return next.handle(request);
  }
}
