<ng-container *ngIf="!triggeredReset">
  <form style="width: -webkit-fill-available">
    <app-form
      [header]="'Forget Password'"
      [myForm]="myForm"
      [formFields]="[
        {
          inputType: 'text',
          formControlName: 'email',
          label: 'Email Id or Username*'
        }
      ]"
    >
    </app-form>

    <div class="forgetPassword-container">
      <app-login-page-button
        [title]="'Send email to reset password'"
        [disabled]="myForm.invalid"
        (onButtonClick)="initiateResetPassword()"
      >
      </app-login-page-button>
      <div class="footer-container">
        <app-need-help></app-need-help>
        <app-powered-by-kaleidofin></app-powered-by-kaleidofin>
      </div>
    </div>
  </form>
</ng-container>
<ng-container *ngIf="triggeredReset">
  <app-reset-password-notify
    (onClick)="initiateResetPassword()"
  ></app-reset-password-notify>
</ng-container>
