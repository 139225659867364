<div class="dialog-container">
  <div mat-dialog-content class="dialog-content-text">
    <mat-icon
      class="closeicon"
      (click)="proceed()"
      (keypress)="proceed()"
      tabindex="0"
      >close</mat-icon
    >
    <img
      *ngIf="!data.isErrorDisplay"
      src="assets/images/common/upload-success.svg"
      alt="Success"
    />
    <img
      *ngIf="data.isErrorDisplay"
      src="assets/images/common/warning.svg"
      alt="Error"
    />
    <p>
      {{ data.description }}
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="action-buttons">
      <button mat-button class="button4 cancel btn btn-primary" (click)="proceed()">
        {{ buttonText }}
      </button>
      <button
        *ngIf="additionalButton"
        mat-button
        class="button4 cancel btn btn-primary"
        (click)="proceed()"
      >
        {{ additionalButton?.text }}
      </button>
    </div>
  </div>
</div>
