import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AmlService } from '../../services/anti-money-laundering.service';

@Component({
  selector: 'app-anti-money-laundering',
  templateUrl: './anti-money-laundering.component.html',
  styleUrls: ['./anti-money-laundering.component.scss']
})
export class AntiMoneyLaunderingComponent implements OnInit {
  @Input() loanApplicationId: any = "";
  @Input() branchCode: any = "";
  @Output() loadAmlDetails = new EventEmitter<any>();
 
  amlDetails: any[] = [];
  displayDetails: boolean = true;
  
  constructor(
    private amlService: AmlService
  ) { }

  ngOnInit(): void {
    this.loadAmlDetails.emit();
    this.amlService
     .getAmlDataSubject().subscribe(
      (response)=>{
        this.amlDetails = [...response];
      },
      (error)=>{
        console.error(error);
      }
     )
  }

  refreshAmlData(): void {
    this.loadAmlDetails.emit();
  }

}
