export const LENDER_CONFIGURATIONS = {
  KCPL: {
    logo: "assets/images/admin-tool-login.svg",
    useFooter: true,
    defaultLogoutRoute: "/",
    lenderCode: "KCPL",
  },
  ICICI: {
    logo: "assets/images/admin-tool-login.svg",
    useFooter: true,
    defaultLogoutRoute: "/",
    lenderCode: "ICICI",
  },
  AFL: {
    logo: "assets/images/admin-tool-login.svg",
    useFooter: true,
    defaultLogoutRoute: "/",
    lenderCode: "AFL",
  },
  DCB: {
    logo: "assets/images/dcb_logo.svg",
    logoClass: "dcb-logo",
    useFooter: false,
    defaultLogoutRoute: "/DCB",
    lenderCode: "DCB",
  },
  DCBMFI: {
    logo: "assets/images/dcb_logo.svg",
    logoClass: "dcb-logo",
    useFooter: false,
    defaultLogoutRoute: "/DCB",
    lenderCode: "DCBMFI",
  },
};
