import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService, PrincipalService } from "src/app/core";

@Component({
  selector: "ig-logout",
  templateUrl: "./logout.component.html",
  styleUrls: ["./logout.component.css"],
})
export class LogoutComponent {
  isAuthenticated: boolean = false;
  hidden: boolean = true;
  username: string = "";

  constructor(
    private loginService: LoginService,
    private router: Router,
    private principalService: PrincipalService
  ) {
    this.isAuthenticated = false;
    this.username = this.principalService.getUserName();
  }

  logout() {
    this.loginService.logout();
  }

  getInitials(): string {
    if (!this.username) return '';
    const names = this.username.split(' ');
    return names.map(name => name.charAt(0)).join('').toUpperCase();
  }
  contact() {
    this.router.navigate(["contact"]);
  }
}
