<app-dynamic-render-component
  [title]="title || ''"
  [uiFields]="uiFields"
  [uiFieldsMap]="uiFieldsMap"
  [isSubHeading]="true"
  [loanId]="loanId"
  [editSections]="editSections"
  [enableEdit]="enableEdit"
  [hideEditAction]="hideEditAction"
  [customButtons]="customButtons"
  [uiApiKey]="uiApiKey"
  [sectionTitle]="sectionTitle"
></app-dynamic-render-component>
