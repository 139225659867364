import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { getProperty } from "src/app/utils/app.utils";
import { AuthJwtService } from "../auth/auth-jwt.service";
import { PrincipalService } from "../auth/principal.service";
import { AssociateLenderService } from "src/app/entities/kaleido-credit/services/associate-lender/associate-lender.service";
import { LENDER_CONFIGURATIONS } from "src/app/constants/lender.config";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(
    private principal: PrincipalService,
    private authServerProvider: AuthJwtService,
    private readonly associateLenderService: AssociateLenderService,
    private router: Router
  ) {}

  handleTokenError(error: any): Promise<any> | void {
    if (error?.status !== 401) {
      return this.logout();
    }

    const status: string = getProperty(error, "error.status", "");

    switch (status) {
      case "PASSWORD_EXPIRED":
        return this.router.navigate(["expired"]);
      case "ACCOUNT_TEMPORARILY_LOCKED":
        return this.router.navigate(["lockout"]);
      case "PASSWORD_RESET":
        return this.router.navigate(["reset/finish"], {
          queryParams: { changePassword: true },
        });
      default:
        return this.logout();
    }
  }

  logout() {
    this.authServerProvider.logout().then(() => {
      this.principal.authenticate(null);
      localStorage.clear();
      sessionStorage.clear();
      let logoutRoute = "/";
      let lenderCode = this.associateLenderService.getLenderCode();
      if (lenderCode) {
        let { defaultLogoutRoute = "/" } = LENDER_CONFIGURATIONS[lenderCode];
        logoutRoute = defaultLogoutRoute;
      }
      this.router.navigateByUrl(logoutRoute);
    });
  }
}
