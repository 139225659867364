import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { getProperty } from "src/app/utils/app.utils";
import { SubscriptionReviewService } from "../../services/customer-group/subscription-review/subscription-review.service";
import { PdfExportService } from "../../services/pdfexport.service";

@Component({
  selector: "app-ekyc-report",
  templateUrl: "./ekyc-report.component.html",
  styleUrls: ["./ekyc-report.component.scss"],
})
export class EkycReportComponent implements OnInit {
  ekycData: any = {};
  applicantName: string = null;
  imageUrl: string = null;

  tableRow: Array<{label: string, propertyKey: string}> = [
    { label: "Careof (C/O)", propertyKey: "careof" },
    { label: "House No", propertyKey: "house" },
    { label: "Street", propertyKey: "street" },
    { label: "Landmark", propertyKey: "locality" },
    { label: "Locality", propertyKey: "landmark" },
    { label: "City", propertyKey: "city" },
    { label: "Sub District", propertyKey: "subDistrict" },
    { label: "District", propertyKey: "district" },
    { label: "State", propertyKey: "state" },
    { label: "Pin code", propertyKey: "pincode" },
    { label: "Post Office", propertyKey: "postoffice" },
    { label: "Country", propertyKey: "country" },
  ];

  constructor(
    private route: ActivatedRoute,
    private subscriptionReviewService: SubscriptionReviewService,
    private pdfExportService: PdfExportService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params.hasOwnProperty("data")) {
        const serializedData = params["data"];
        let data = JSON.parse(decodeURIComponent(window.atob(serializedData)));
        this.ekycData = data || {};
        const nameObj: any = getProperty(this.ekycData, "name.value", {});
        this.applicantName = getProperty(nameObj, "bcData", null);
        const fileId: number = getProperty(this.ekycData, "fileId.value", null);
        this.getImageUrl(fileId);
      }
    });
  }

  getImageUrl(fileId: number): void {
    this.subscriptionReviewService
      .getFileDtoFromFileId(fileId)
      .subscribe((response) => {
        this.imageUrl = getProperty(response, "path", null);
      });
  }
  downloadPdf(): void {
    this.pdfExportService.downloadCurrentPageAsPdf(`${this.applicantName}_KYC`);
  }
}
