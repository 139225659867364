import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ErrorStateMatcher } from "@angular/material/core";
import { Router } from "@angular/router";
import { JhiEventManager } from "ng-jhipster";
import {
  LoginService,
  PrincipalService,
  StateStorageService,
} from "src/app/core";
import { AuthorizedRoutes } from "src/app/core/auth/authorized-routes.service";
import { DashboardService } from "../../entities/dashboard/dashboard.service";

@Component({
  selector: "ig-login-modal",
  templateUrl: "./login.component.html",
})
export class IgLoginModalComponent implements OnInit {
  @Output() openredirectModal: EventEmitter<any> = new EventEmitter<any>();
  hideOrShowCarousel: boolean = false;
  authenticationError: boolean = false;
  isOTPScreen: boolean = false;
  password: string = "";
  rememberMe: boolean = false;
  username: string = "";
  credentials: any;
  hide = true;
  loginForm: FormGroup;
  matchers = new ErrorStateMatcher();
  partnerMismatch: boolean = false;
  redirectURL: string = "";

  constructor(
    private dashboardService: DashboardService,
    private eventManager: JhiEventManager,
    private loginService: LoginService,
    private stateStorageService: StateStorageService,
    private router: Router,
    private formBuilder: FormBuilder,
    private principal: PrincipalService,
    private authorizedRouter: AuthorizedRoutes
  ) {
    this.credentials = {};
    this.loginForm = formBuilder.group({});
  }
  ngOnInit() {
    this.dashboardService.clearMessage();
    this.isOTPScreen = false;
    this.validation();
  }

  cancel() {
    this.credentials = {
      username: null,
      password: null,
      rememberMe: true,
    };
    this.authenticationError = false;
  }
  getErrorMessage() {
    return "Invalid Username or Password";
  }
  login() {
    this.username = this.loginForm.get("username")?.value;
    this.password = this.loginForm.get("password")?.value;
    this.loginService
      .login(
        {
          username: this.username,
          password: this.password,
          rememberMe: this.rememberMe,
        },
        "PASSWORD"
      )
      .then(() => {
        this.authenticationError = false;
        if (
          this.router.url === "/register" ||
          this.router.url.startsWith("/activate/") ||
          this.router.url.startsWith("/reset/")
        )  {
          this.router.navigate([""]);
        }

        this.eventManager.broadcast({
          name: "authenticationSuccess",
          content: "Sending Authentication Success",
        });

        const redirect = this.stateStorageService.getUrl();

        if (redirect) {
          this.stateStorageService.storeUrl("");
          this.router.navigate([redirect]);
        } else {
          this.stateStorageService.storeUrl("");
          this.authorizedRouter.navigate();
        }
      })
      .catch((err) => {
        this.authenticationError = true;
        this.partnerMismatch = false;
        if (this.principal.getPartnerMismatchURL()) {
          this.partnerMismatch = true;
          this.openredirectModal.emit(this.principal.getPartnerMismatchURL());
        } else {
          this.openredirectModal.emit();
        }
      });
  }

  register() {
    this.router.navigate(["/login/otp"]);
  }

  requestResetPassword() {
    this.router.navigate(["/reset", "request"]);
  }

  validation() {
    this.loginForm = this.formBuilder.group({
      username: ["", [Validators.required]],
      password: ["", [Validators.required]],
    });
  }

  googleEvent(event: any, type: any) {
    console.log('google event Iniated');
  }
  
  resetPassword() {
    this.router.navigate(["reset"]);
  }

}
