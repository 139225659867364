<form
  name="editForm"
  novalidate
  #editForm="ngForm"
  [formGroup]="reportService.form"
>
  <mat-form-field appearance="none" class="select-boxes">
    <mat-label>Select</mat-label>
    <mat-select
      placeholder="Report Type"
      formControlName="reportType"
      multiple
      [(ngModel)]="reportService.selectedReport"
    >
      <app-select-all-combo-box
        [model]="reportService.form.get('reportType')"
        [values]="reportService.reportTypeOptions"
        text="All"
      >
      </app-select-all-combo-box>
      <mat-option
        *ngFor="let report of reportService.reportTypeOptions"
        [value]="report"
        >{{ report.name }}</mat-option
      >
    </mat-select>
  </mat-form-field>
</form>
