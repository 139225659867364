<div class="popup-container">
    <div class="header-container">
        <h3>Confirmation</h3>
        <button (click)="closeDialog()">
            <mat-icon>close</mat-icon>
        </button>
    </div>
    <div class="form-container">
        <div>
            <p>Are you sure you want to {{decision}} the deviation for {{approver}}?</p>
        </div>
        <mat-form-field appearance="fill" class="form-field">
            <mat-label>Type Your Remarks</mat-label>
            <input matInput type="text" placeholder="Type Your Remarks" [(ngModel)]="comment" />
        </mat-form-field>
        <div class="approve-button-container">
            <button (click)="closeDialog()" mat-flat-button class="cancel-button">Cancel</button>
            <button mat-flat-button (click)="setComment()" class="action-button" [class]="decision">Yes, {{decision | titlecase}}</button>
        </div>
    </div>
</div>