<div class="title-container">
  <div>
    <mat-checkbox
      [(ngModel)]="doc.tagged"
      [disabled]="!validateAuthority || getDisabled()"
      [hidden]="!isApplicationAssigned"
      (change)="toggleDocumentSelection(doc?.id)"
      >Select</mat-checkbox
    >
  </div>
  <div class="button-container">
    <button
      mat-raised-button
      (click)="onTag()"
      [hidden]="!isApplicationAssigned"
      class="btn-secondary"
      [disabled]="!validateAuthority || getDisabled()"
    >
      <span>Tag</span>
    </button>
    &nbsp;&nbsp;
    <button
      mat-raised-button
      type="button"
      class="btn-primary"
      data-dismiss="modal"
      [hidden]="!isPdf(doc)"
      (click)="openPdf(doc)"
    >
      <span [class]="doc?.icon"></span>&nbsp;<span>{{ doc?.actionText }}</span>
    </button>
    <button
      mat-raised-button
      (click)="onEdit()"
      class="btn-primary"
      [disabled]="
        !validateAuthority || disableEditButtons || !isApplicationAssigned
      "
      [hidden]="isPdf(doc) || hideEditButtons || !isApplicationAssigned"
    >
      <span class="fa fa-pencil"></span>&nbsp;<span
        jhiTranslate="entity.action.edit"
        >Edit&nbsp;&nbsp;
      </span>
    </button>
  </div>
</div>
