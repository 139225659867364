import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { getProperty } from 'src/app/utils/app.utils';
import { AmlService } from '../../services/anti-money-laundering.service';

const verify = "assets/images/aml/verify.svg";
const verified = "assets/images/aml/verified.svg";

@Component({
  selector: 'app-aml-hit-table',
  templateUrl: './aml-hit-table.component.html',
  styleUrls: ['./aml-hit-table.component.scss']
})
export class AmlHitTableComponent {
  @Input() hits: any[] = [];
  @Input() branchCode: any = "";
  @Input() yearOfBirth: any = "";
  @Input() loanApplicationId: any = "";
  @Output() loadAmlDetails: EventEmitter<any> = new EventEmitter<any>();
  showDetail: boolean = false;
  tableColumns: any[] = [
    {
      key: "hitNo",
      displayValue: "Hit no.",
    },
    {
      key: "searchName",
      displayValue: "Search name",
    },
    {
      key: "entity",
      displayValue: "Entity",
    },
    {
      key: "alias",
      displayValue: "AKA",
    },
    {
      key: "country",
      displayValue: "Country",
    },
    {
      key: "amlType",
      displayValue: "AML Type",
    },
]

  constructor(
    private amlService: AmlService,
    private snackbar: MatSnackBar,
    private domSanitizer: DomSanitizer
  ) { }

  updateDisplay(): void {
    this.showDetail = !this.showDetail;
  }
  getVerifyStatus(hit: any = {}): string{
    const action = getProperty(hit, "action", "verify").toLowerCase();
    return action;
  }

  fetchDisplayIcon(action: string = ""): SafeResourceUrl{
    let icon = verify;
    if(action.toLowerCase() === "verified"){
      icon = verified;
    }
    return this.domSanitizer.bypassSecurityTrustResourceUrl(icon);
  }
  verifyAml(hit: any = {}): void{
    const status = getProperty(hit,"action","").toLowerCase();
    if(status === 'verified'){
      return;
    }
    const payload: any = {};
    payload.name = getProperty(hit,"searchName", null);
    payload.matchNo = getProperty(hit, "hitNo", null);
    payload.yearOfBirth = this.yearOfBirth;
    payload.loanApplicationId = this.loanApplicationId;
    this.amlService.verifyAmlDetails(payload, this.branchCode)
     .subscribe(
      ()=>{
        hit.action = 'Verified';
      },
      (error)=> {
        console.error(error);
        this.snackbar.open(
          `${error.error.message}`,
          "",
          { duration: 5000 }
        );
      }
      
     )
  }
}
