<div>
  <div mat-dialog-title class="dialog-title">
    <div class="section-title">Confirmation</div>
    <mat-icon (click)="cancel()" onKeyPress="">close</mat-icon>
  </div>
  <div mat-dialog-content class="dialog-content-text">
    <div class="alert-text">
      {{ message }}
    </div>
    <br />
    <div class="alert-text">Do you wish to proceed?</div>
  </div>
  <div mat-dialog-actions class="action-buttons">
    <button mat-button class="btn btn-primary" cdkFocusInitial (click)="handleYes()">Yes</button>
    <button mat-button class="button4 cancel" (click)="cancel()">No</button>
  </div>
</div>
