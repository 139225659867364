import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injector } from "@angular/core";
import { Observable } from "rxjs";

import { Router } from "@angular/router";
import { JhiEventManager } from "ng-jhipster";
import { tap } from "rxjs/operators";
import { LoginService } from "src/app/core";

export class AuthExpiredInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private eventManager: JhiEventManager
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              const loginService: LoginService =
                this.injector.get(LoginService);
              const router: Router = this.injector.get(Router);
              if (localStorage.getItem("authenticationToken")) {
                loginService.logout();
              }
              router.navigateByUrl("");
              this.eventManager.broadcast({
                name: "authenticationFailure",
                content: err,
              });
            }
          }
        }
      )
    );
  }
}
