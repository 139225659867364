import { Component, Input } from "@angular/core";
import { get } from "lodash";
import { DedupeData, DedupeResponseDTO } from "../dedupe.models";
import { DedupeService } from "../dedupe.service";

@Component({
  selector: "app-dedupe-accordion",
  templateUrl: "./dedupe-accordion.component.html",
  styleUrls: ["./dedupe-accordion.component.scss"],
})
export class DedupeAccordionComponent {
  @Input() data: DedupeData = undefined;
  dedupeData: DedupeResponseDTO[] = [];

  panelOpenState = false;
  customerId:number = null;
  constructor(private dedupeService: DedupeService) {}


  updateAccordionOpenState() {
    this.panelOpenState = true;
    const dedupeData = get(this.data, "customerInformation", {});
    const type = get(this.data, "type", null);
    this.customerId = get(dedupeData, "customerNumber", null);
    this.dedupeService
      .fetchDedupeData(this.data.id, type)
      .subscribe((response) => {        
        this.dedupeData = get(response, "dedupeResponseDTOList")||[];
      });
  }
  updateAccordionCloseState() {
    this.panelOpenState = false;
  }
}
