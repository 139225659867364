import { Component, Input, Output, OnInit,EventEmitter } from "@angular/core";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AdditionalData } from "./additionaldata.model";
import { CustomerService } from "../../../services/customer/customer.service";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "jhi-additional-data",
  templateUrl: "./additional-data.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class AdditionalDataComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  addionalDataMap: AdditionalData[] = [];

  public additionalData: any;
  public initialData: any;
  public isEditing: boolean;
  @Output() reloadAfterSave = new EventEmitter<any>();

  @Output()
  saveProfileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private customerService: CustomerService,
    private snackBar: MatSnackBar
  ) {
    this.isEditing = false;
    this.saveProfileChange = new EventEmitter();
    this.additionalData = {};
    this.initialData = {};
  }

  ngOnInit() {
    this.initialMappingOfField();
  
  }

  initialMappingOfField() {
    this.addionalDataMap = [];
    const additionalData =
      JSON.parse(getProperty(this.loanDetails,'loanApplicationDTO.additionalData') ||"{}");
    for (const key in additionalData) {
      this.additionalData[key] = additionalData[key];
      let addData: AdditionalData = {
        propertyName: key,
        propertyValue: additionalData[key],
      };
      this.addionalDataMap.push(addData);
    }
  }
  ngOnChanges() {
    this.initialMappingOfField();
  }

  reverseKeyMappingOnSave() {
    const newMapper = {};
    for (const key in this.additionalData) {
      newMapper[this.additionalData[key]] = this.additionalData[key];
    }
    return newMapper;
  }

  public cancel(): void {
    this.isEditing = false;
    this.additionalData = {...this.additionalData};
  }

  public edit(): void {
    this.isEditing = true;
  }

  public save(): void {
    const formData = this.reverseKeyMappingOnSave();
    const payload = {
          ...JSON.parse(this.loanDetails.customerDTO.profile),
       Business: formData
      };
    this.customerService
      .updateProfile(this.loanDetails.customerDTO.id, payload)
      .subscribe(
        () => {
          this.isEditing = false;
          this.reloadAfterSave.emit("About the business");
          this.snackBar.open("Remarks", "Successfully Saved!", {
            duration: 3000,
          });
        },
        (response) => {
          this.snackBar.open("Error in saving record", "Error", {
            duration: 3000,
          });
        }
      );
  }
}
