import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { get } from "lodash";
import { BehaviorSubject } from "rxjs";
import { DataItem } from "src/app/entities/kaleido-credit/genreport/DataItem.model";
import {
  LOAN_APPLICATION_DETAILS,
  createSelectAllObjects,
} from "src/app/entities/kaleido-credit/genreport/kicredit-report.constants";
import { MatSelectOption } from "src/app/entities/kaleido-credit/loan/constant";
import { Partners } from "src/app/entities/kaleido-credit/models/partners/partners.model";
import { formattedDateForDateUtils } from "src/app/shared";
import { CustomValidator } from "src/app/shared/validations/custom.validation";
import { getProperty } from "src/app/utils/app.utils";
import { DownloadLoanReportPopupComponent } from "../../../genreport/download-loan-report-popup/download-loan-report-popup.component";
import { ReportGenerationService } from "../../../genreport/kcredit-reportgen.service";
import { AssociateLenderService } from "../../../services/associate-lender/associate-lender.service";

const DEFAULT_SELECTION_VALUE = {
  viewValue: "",
  value: "",
  reportTableValue: "",
};
@Component({
  selector: "app-reports-filter",
  templateUrl: "./reports-filter.component.html",
  styleUrls: ["./reports-filter.component.scss"],
})
export class ReportsFilterComponent implements OnInit {
  config: BehaviorSubject<any> = undefined;
  form: FormGroup;

  purposeList: MatSelectOption[] = [];
  reportTypeOptions: DataItem[] = [];
  loanTypeOptions: DataItem[];
  partnerListOptions: Partners[] = [];
  statusMenus: any = [];
  selectedReport: MatSelectOption = DEFAULT_SELECTION_VALUE;
  selectedPartners: Partners[] = [];
  selectedLoanType: string[] = [];
  startDate: any = "";
  endDate: any = "";
  selectedPurpose: MatSelectOption = DEFAULT_SELECTION_VALUE;
  selectedStatus: any = {};

  maxDate = new Date();
  minDate = new Date(2018, 1, 1);

  // MetaData
  internalReports: any = [];
  externalReports: any = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private lenderService: AssociateLenderService,
    private reportGenerationService: ReportGenerationService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private customValidator: CustomValidator,
    private dialogRef : MatDialogRef<ReportsFilterComponent>
    
  ) {
    // Setup form
    this.form = this.fb.group({
      reportType: [[],[Validators.required]],
      loanType: [[],[Validators.required]],
      partners: [[],[Validators.required]],
      startDateField: [null,[Validators.required]],
      endDateField: [null,[Validators.required]],
      purpose :[{},[Validators.required]]
    },
      {
        validators :customValidator.dateRangeValidator('startDateField','endDateField')
      }
    );

    this.config = data.prop1;
    
  }

  ngOnInit(): void {
    this.config.subscribe((uiConfig) => {
      this.purposeList = get(uiConfig, "purpose", []);
      this.internalReports = get(uiConfig, "internalReportTypes", []);
      this.externalReports = get(uiConfig, "externalReportTypes", []);
      this.statusMenus = get(uiConfig, "status", []);

      let loanTypes = get(uiConfig, "loanTypes", []);
      this.loanTypeOptions = createSelectAllObjects(loanTypes);
    });

    this.lenderService.getPartnersLinked().subscribe(
      (partners) => {
        console.log(partners);
        this.partnerListOptions = partners;
      },
      (error) => console.error(error)
    );
  }

  updatePurpose(selectedPurpose: MatSelectOption) {
    
    this.selectedPurpose = selectedPurpose;
    let reportTypes = [];
    switch (selectedPurpose.value) {
      case "internal":
        reportTypes = this.internalReports;
        break;
      case "external":
        reportTypes = this.externalReports;
        break;
      case "error":
        console.log('error');
        break;
      default:
        reportTypes = [];
        break;
    }
    this.reportTypeOptions = reportTypes;
    this.selectedReport = DEFAULT_SELECTION_VALUE;
  }
  enableStatusMenu() {
    const isInternalPurpose =
      getProperty(this.selectedPurpose, "value", "") === "internal";
    const requiredItems = [LOAN_APPLICATION_DETAILS, "CKYC", "GENERATE_LOAN_APPLICATION_REPORT"];

    return (
      isInternalPurpose &&
      requiredItems.some((item) => this.selectedReport.value.includes(item))
    );
  }

  updateSelectedStatus(status: any) {
    this.selectedStatus = status;
  }

  updateDate(dateObject = {}, dateKey = "startDate") {
    this[dateKey] = dateObject["value"];
  }


  generateReport() {
    let statuses = [];
    if (!this.selectedStatus.length) {
      statuses = [
        this.selectedReport.viewValue.includes("Upload")
          ? "TO_BE_PICKED_UP"
          : "READY_FOR_UPDATE",
      ];
    } else {
      statuses = this.selectedStatus.map((status) =>
        this.fetchStatusValue(status)
      );
    }
    const openDialog = getProperty(this.selectedReport,"openDownloadPopup", false);
    const reportTypes = getProperty(this.selectedReport, "reportTypes", null);
    let filterParams: any = {
      startDate: formattedDateForDateUtils(this.startDate),
      endDate: formattedDateForDateUtils(this.endDate),
      purpose: getProperty(this.selectedPurpose, "value", null),
      loanTypes: this.selectedLoanType.map((customerType) =>
        getProperty(customerType, "name", "")
      ),
      status: statuses,
      selectedReport: this.selectedReport.value,
      selectedReportText: this.selectedReport.reportTableValue,
      selectedPartners: this.selectedPartners.map((data) =>
        getProperty(data, "id", null)
      ),
      selectedPartnersNames: this.selectedPartners.map((data) =>
        getProperty(data, "name", null)
      ),
    };
    if(openDialog){
      const dialogRef = this.dialog.open
        (DownloadLoanReportPopupComponent, {
        width: "40vw",
        data :{
          reports : reportTypes
        }
      })
      dialogRef.afterClosed().subscribe(
        (response) => {
          console.log(response, "closed");
          filterParams.downloadReportType = [response];
          filterParams.loanReportTypes = ["LoanApplicationDetails"];
          this.fetchReports(filterParams);
        }
      )
    }
    else {
      this.fetchReports(filterParams);
    }
    
  }

  fetchReports(requestsParams: any) {
    this.reportGenerationService.generateLoanReports(requestsParams).subscribe(
      () => {
        this.data.callback({
          reportType: getProperty(requestsParams, "selectedReport", ""),
          size: 1,
          successMessage: "Reports Generated Successfully",
        })
        this.dialogRef.close(this.form.value);
      },
      (error) => {
        let errorResponse: any = getProperty(error, "error", "{}");
        errorResponse = JSON.parse(errorResponse);
        this.snackbar.open(getProperty(errorResponse, "message", "Error"), "", {
          duration: 5000,
        });
      }
    );
  }

  checkForLoanApplicationDownload() {
    if (
      getProperty(this.selectedPurpose, "value", "") === "internal" &&
      this.selectedReport.value.includes(LOAN_APPLICATION_DETAILS)
    ) {
      if (this.selectedStatus.length) {
        return true;
      }
    }
    return false;
  }

  fetchStatusValue(status: any) {
    if ("Success".includes(getProperty(status, "viewValue"))) {
      if (this.selectedReport.viewValue.includes("Upload")) {
          return getProperty(status, "value", "");
      }
      return "SENT_IN_UPDATE_FILE";
    } else
    {
      return getProperty(status, "value", "");
    }
  }
  onCancel(): void {
    this.dialogRef.close();
  }
}
