<div class="main">
    <div class="help-support-container">
        <div class="headers-container">
            <div class="header"><span>Need Help!</span></div>
            <div class="sub-header"><span>Our support team is just a click away</span></div>
        </div>
        <div class="contact-info-container">
            <div class="contact-info">
                <div>
                    <img src="assets/images/common/phone-forwarded.svg" alt="phone" class="info-icon">
                </div>
                <div class="info-displayer">
                    <span class="info-header">Call us on</span>
                    <a href="tel:+919842012345">9842012345</a>
                </div>
            </div>
            <div class="contact-info">
                <div>
                    <img src="assets/images/common/mail.svg" alt="mail" class="info-icon">
                </div>
                <div class="info-displayer">
                    <span class="info-header">Mail us on</span>
                    <a href="mailto:support@kicredit.com">support&#64;kicredit.com</a>
                </div>
            </div>
        </div>
    </div>
</div>