import { Route } from "@angular/router";
import { IgKaleidofinHomeModalComponent } from "./kaleidofin-home.component";
import { LENDER_CONFIGURATIONS } from "../constants/lender.config";

export const HOME_ROUTE: Route = {
  path: "",
  component: IgKaleidofinHomeModalComponent,
  data: {
    authorities: [],
    pageTitle: "Kaleidofin",
    metaData: LENDER_CONFIGURATIONS.KCPL,
  },
};

export const DCB_ROUTE: Route = {
  path: "DCB",
  component: IgKaleidofinHomeModalComponent,
  data: {
    authorities: [],
    pageTitle: "DCB",
    metaData: LENDER_CONFIGURATIONS.DCB,
  },
};
