<mat-form-field appearance="outline" [matTooltip]="toolTipLabel"
  [matTooltipDisabled]="!showToolTip"
  matTooltipPosition="above">
  <mat-label>{{ label }}</mat-label>
  <input 
    matInput 
    [formControl]="inputFormControl"
    [maxlength]="inputLength" 
    [required]="isRequired"
  />
</mat-form-field>
<mat-error *ngIf="inputFormControl.errors?.['invalidPattern']">
  {{errorMessage}}
</mat-error>
