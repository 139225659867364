<mat-accordion>
  <mat-expansion-panel [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="section-title-text" id="businessRegistrationDetails">
          Business registration details
        </div></mat-panel-title
      >
      <mat-panel-description> </mat-panel-description>
    </mat-expansion-panel-header>

    <app-business-reg-detail-item
      title="Business registration certificate details"
      documentType="BusinessRegistrationCertificate"
      [fields]="businessRegistrationfields"
    ></app-business-reg-detail-item>

    <app-business-reg-detail-item
      title="Trade/Export license details"
      documentType="TradeExportLicense"
      [fields]="tradeLicenseFields"
    ></app-business-reg-detail-item>
  </mat-expansion-panel>
</mat-accordion>
