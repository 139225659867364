import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { RouterModule } from "@angular/router";
import { SharedModule } from "../shared";
import { HomeComponent } from "./home.component";
import { DCB_ROUTE, HOME_ROUTE, RESET_PASSWORD, USER_LOCKOUT } from "./home.route";

@NgModule({
  imports: [SharedModule, RouterModule.forChild([HOME_ROUTE, DCB_ROUTE, RESET_PASSWORD, USER_LOCKOUT])],
  declarations: [HomeComponent],
  entryComponents: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class Ignition5BaseHomeModule {}
