import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { KREDILINE_SERVER_URL } from 'src/app/app.constants';
import { GET_AML_DETAILS, INITIATE_AML, VERIFY_AML } from 'src/app/shared/constants/Api.constants';


@Injectable({
  providedIn: 'root'
})
export class AmlService {
  
  amlDataSubject: BehaviorSubject<any> = new BehaviorSubject([]); 
  amlData: any;
  constructor( private http : HttpClient ) { }

  fetchAmlData(partnerCustomerId: any = null, partnerLoanId: any = null, branchCode: any = null): void{
    const params = new HttpParams().append("partnerCustomerId", partnerCustomerId).append("partnerLoanId", partnerLoanId);

     this.http.get(`${KREDILINE_SERVER_URL}${GET_AML_DETAILS}`,
     {params, headers: {
      "X-kaleidofin-BranchCode" : branchCode
     }}
     ).subscribe(
      (response)=>{
        this.amlDataSubject.next(response);
        this.amlData = response;
      },
      (error)=>{
        console.error(error);
      }
     )
  }

  initiateAML(applicantDetails: any = {}, branchCode: any = null): void{
    this.http.post(`${KREDILINE_SERVER_URL}${INITIATE_AML}`,
     applicantDetails,
     {headers:{
      "X-kaleidofin-BranchCode" : branchCode
     }
    })
    .subscribe(
      (response)=>{
        this.setAmlDataSubject(this.amlData);
      },
      (error)=> console.error(error)

    )
  
  }

  verifyAmlDetails(applicantDetails: any = {}, branchCode: any = null): Observable<any>{
    return this.http.put(`${KREDILINE_SERVER_URL}${VERIFY_AML}`, applicantDetails, {
      headers:{
        "X-kaleidofin-BranchCode" : branchCode
      }
    })
  }

  getAmlDataSubject(): BehaviorSubject<any>{
    return this.amlDataSubject;
  }

  setAmlDataSubject(amlData: any[]): void{
    this.amlDataSubject.next(amlData);
  }
}
