import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { CustomValidator } from "src/app/shared/validations/custom.validation";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-input-field",
  templateUrl: "./input-field.component.html",
})
export class InputFieldComponent implements OnInit, OnChanges {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label: string = "";
  @Input() isRequired: boolean = false;
  @Input() errors: any = {};
  @Input() inputLength: number;
  @Input() disabled: boolean = false;
  @Input() useRegex: boolean = false;
  @Input() customRegex: string = "";
  @Input() errorMessage: string = "Please enter a valid input.";
  @Input() showToolTip: boolean = false;
  @Input() toolTipLabel: string = null;

  inputFormControl: FormControl = new FormControl();

  constructor(readonly customValidator: CustomValidator) {}

  ngOnInit(): void {
    if (this.useRegex) {
      this.inputFormControl = new FormControl(
        { value: this.dataObj?.value ?? "", disabled: this.disabled },
        this.customValidator.regexValidator(this.customRegex)
      );
    } else {
      this.inputFormControl = new FormControl({
        value: this.dataObj?.value ?? "",
        disabled: this.disabled,
      });
    }
    this.inputFormControl.valueChanges.subscribe((value) => {
      this.dataObj.value = value;
    });

    if (this.disabled) {
      this.inputFormControl.disable();
    } else {
      this.inputFormControl.enable();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    const disableChange: SimpleChange = getProperty(changes, "disabled", {});
    if (disableChange?.currentValue) {
      this.inputFormControl.disable();
    } else {
      this.inputFormControl.enable();
    }
  }
}
