<mat-accordion>
  <mat-expansion-panel
    (opened)="updateAccordionOpenState()"
    (closed)="updateAccordionCloseState()"
  >
    <mat-expansion-panel-header class="accordion-header">
      <mat-panel-title class="section-title">
        Dedupe - {{ data.title }}
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div *ngIf="panelOpenState">
      <app-dedupe-form [data]="data.customerInformation"></app-dedupe-form>
      <app-dedupe-data [customerId]="customerId" [tableItems]="dedupeData"></app-dedupe-data>
    </div>
  </mat-expansion-panel>
</mat-accordion>
