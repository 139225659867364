import { Component, Input, Output, OnInit , EventEmitter} from "@angular/core";
import { KcreditLoanDetailsModel } from "../../kcredit-loanDetails.model";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Partner } from "../../../models/partner.model";
import { LoanApplicationService } from "../../../services/krediline/loan-application.service";

@Component({
  selector: "jhi-partner-info",
  templateUrl: "./partner-info.html",
  styleUrls: ["../../kcredit-loan.css"],
})
export class PartnerInfoComponent implements OnInit {
  @Input() loanDetails: KcreditLoanDetailsModel;
  @Input() disableEdit: boolean;
  partner: Partner;
  initialLoanFee: Partner;
  public isEditing: boolean;
  @Output() reloadAfterSave = new EventEmitter<any>();

  @Output()
  saveProfileChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private loanApplicationService: LoanApplicationService,
    private snackBar: MatSnackBar
  ) {
    this.isEditing = false;
    this.saveProfileChange = new EventEmitter();
  }

  ngOnInit() {
    this.partner = { ...this.loanDetails.partnerDTO };
    this.initialLoanFee = { ...this.loanDetails.partnerDTO };
  }

  public cancel(): void {
    this.isEditing = false;
    this.partner = { ...this.initialLoanFee };
  }

  public edit(): void {
    this.isEditing = true;
  }

  public save(): void {
    this.loanApplicationService.update(this.partner).subscribe(
      (res) => {
        this.isEditing = false;
        this.reloadAfterSave.emit("KCPL fee details");
      },
      (response) => {
        this.snackBar.open("Error in saving record", "Error", {
          duration: 3000,
        });
      }
    );
  }
}
