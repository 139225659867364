import { HttpClient, HttpParams, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { JhiDateUtils } from "ng-jhipster";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import {
  KALEIDO_SERVER_API_URL,
  KREDILINE_SERVER_URL,
} from "src/app/app.constants";
import { LoanApplicationSearchSpecification } from "../../models/krediline/loan-application-download.model";
import { LoanApplication } from "../../models/krediline/loan-application.model";
import { LoanDetails } from "../../models/krediline/loan-details.model";
import { LoanInfoDTO } from "../../models/krediline/repayment/loan-info-DTO.model";
import {
  LOAN_APPLICATIONS,
  LOAN_APPLICATION_COUNT,
  PARTNER_WISE_LOAN_COUNT,
} from "src/app/shared/constants/Api.constants";
import { LoanPhaseMetrics } from "../../models/loan-overview/loan-application-phase-metrics";

@Injectable()
export class LoanApplicationService {
  private resourceUrl = "api/backoffice/loans/applications";
  public loanApplication: LoanApplication;
  public loanApplications: LoanApplication[];
  public loanDetails: LoanDetails;
  public loanInfoDTO: LoanInfoDTO;
  public loanCounts: Map<any, any> = new Map();
  public partnerwiseLoanCounts: Map<string, LoanPhaseMetrics> = new Map();
  currentStatusStr: string;
  newStatusStr: string;
  loanApplicationSearchSpecification: LoanApplicationSearchSpecification;
  constructor(private http: HttpClient, private dateUtils: JhiDateUtils) {
    this.loanApplication = new LoanApplication();
    this.loanDetails = new LoanDetails();
  }

  convertLocalDateFromServer(date: string) {
    if (date !== undefined && date != null) {
      const split = date.split("-");
      const dateObj = {
        year: parseInt(split[0]),
        month: parseInt(split[1]),
        day: parseInt(split[2]),
      };
      return dateObj;
    } else {
      return undefined;
    }
  }

  query(req?: any): Observable<any> {
    req.appliedFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.appliedFromDate
    );
    req.appliedToDate = this.dateUtils.convertLocalDateToServer(
      req.search.appliedToDate
    );
    req.lienFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.lienFromDate
    );
    req.lienToDate = this.dateUtils.convertLocalDateToServer(
      req.search.lienToDate
    );
    req.loanClosedFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.loanClosedFromDate
    );
    req.loanClosedToDate = this.dateUtils.convertLocalDateToServer(
      req.search.loanClosedToDate
    );
    req.emiFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.emiFromDate
    );
    req.emiToDate = this.dateUtils.convertLocalDateToServer(
      req.search.emiToDate
    );
    req.dueFromDate = this.dateUtils.convertLocalDateToServer(
      req.search.dueFromDate
    );
    req.dueToDate = this.dateUtils.convertLocalDateToServer(
      req.search.dueToDate
    );
    req.nextDueDate = this.dateUtils.convertLocalDateToServer(
      req.repaymentDate
    );
    req.applicationStatus = req.search.applicationStatus;
    req.loanPhase = req.search.loanPhase;
    let params = this.createRequestOption(req);
    return this.http
      .get(KREDILINE_SERVER_URL + this.resourceUrl, { params })
      .pipe(map((res: any) => this.convertResponse(res)));
  }

  getKcreditApplications(req?: any): Observable<any> {
    req.applicationStatus = req.search.applicationStatus;
    req.loanPhase = req.search.loanPhase;
    let params = this.createLoanApplicationRequestOption(req);
    return this.http
      .get(KREDILINE_SERVER_URL + this.resourceUrl, {
        params,
        observe: "response",
      })
      .pipe(map((res: HttpResponse<any>) => this.convertResponse(res)));
  }

  fetchLoanApplications(searchParams: any = {}) {
    const params = this.createHttpParams(searchParams);
    return this.http.get(LOAN_APPLICATIONS, { params, observe: "response" });
  }

  fetchLoanApplicationCount(searchParams: any = {}) {
    const params = this.createHttpParams(searchParams);
    return this.http.get(LOAN_APPLICATION_COUNT, { params });
  }

  createHttpParams(searchParams: any = {}) {
    let params = new HttpParams();

    Object.keys(searchParams).forEach((key) => {
      params = params.append(key, searchParams[key]);
    });
    return params;
  }
  /**
   *
   * @param req request to fetch count
   * @returns return map containing partner wise map (loan phase, count of applications)
   */
  getPartnerLoanCounts(
    req: any = {}
  ): Observable<Map<string, LoanPhaseMetrics>> {
    let params = this.createHttpParams(req);
    return this.http.get(PARTNER_WISE_LOAN_COUNT, { params }).pipe(
      map((res) => {
        if (res) {
          for (let partnerName in res) {
            this.partnerwiseLoanCounts.set(
              partnerName,
              new LoanPhaseMetrics(res[partnerName])
            );
          }
        }
        return this.partnerwiseLoanCounts;
      })
    );
  }

  private convertResponse(res: any): any {
    const jsonResponse = res.body;
    let loanApplications = []
    if (jsonResponse.hasOwnProperty("loanApplications")) {
      loanApplications = this.updateDateFields(jsonResponse?.loanApplications);
    }else{
      loanApplications= this.updateDateFields(jsonResponse);
    }
    res.body = {...jsonResponse,loanApplications};
    return res;
  }

  updateDateFields(data: Array<any> = []) {
    return data.map((element) => ({
      ...element,
      dateOfBirth: this.dateUtils.convertLocalDateFromServer(
        element.dateOfBirth
      ),
      spouseDateOfBirth: this.dateUtils.convertLocalDateFromServer(
        element.spouseDateOfBirth
      ),
    }));
  }
  private createLoanApplicationRequestOption(req?: any) {
    let params: HttpParams = new HttpParams();

    if (req) {
      params = params.append("page", req.page);
      params = params.append("size", req.size);
      params = params.append("loanProduct", "kcredit");
      params = params.append("applicationStatus", req.search.applicationStatus);
      params = params.append("loanPhase", req.search.loanPhase);
      params = params.append("searchValues", req.searchValues || "");
      params = this.helperFunction(params, req.search.partnerType, "lendingPartnerCode");
      params = this.helperFunction(params, req.search.loanType, "loanType");
      params = this.helperFunction(params, req.search.partnerId, "partnerId");
      params = this.helperFunction(
        params,
        req.search.bankValidationStatus,
        "bankValidationStatus"
      );
      params = this.helperFunction(
        params,
        req.search.postDisbursementUploadStatus,
        "postDisbursementUploadStatus"
      );
      params = this.helperFunction(params, req.sort, "sort");

      for (const key in req.query) {
        if (req.query[key] !== "" || req.query[key] !== 0) {
          params = params.append(key, req.query[key]);
        }
      }
    }
    return params;
  }
  helperFunction(params, req, str) {
    let temp = params;
    if (req) {
      temp = params.append(str, req);
    }
    return temp;
  }
  private createRequestOption(req?: any): HttpParams {
    let params: HttpParams = new HttpParams();
    if (req) {
      params = params.append("page", req.page);
      params = params.append("size", req.size);
      params = params.append("status", req.search.status);
      params = params.append("filterForList", req.search.filterForList);
      params = params.append(
        "lendingPartnerCode",
        req.search.lendingPartnerCode
      );
      params = params.append("appliedFromDate", req.appliedFromDate);
      params = params.append("appliedToDate", req.appliedToDate);
      params = params.append("lienFromDate", req.lienFromDate);
      params = params.append("lienToDate", req.lienToDate);
      params = params.append("loanClosedFromDate", req.loanClosedFromDate);
      params = params.append("loanClosedToDate", req.loanClosedToDate);
      params = params.append("any", req.search.selectAny);
      params = params.append("loanState", req.loanState);
      params = params.append("emiFromDate", req.emiFromDate);
      params = params.append("emiToDate", req.emiToDate);
      params = params.append("dueFromDate", req.dueFromDate);
      params = params.append("dueToDate", req.dueToDate);
      params = params.append("nextDueDate", req.nextDueDate);
      params = params.append("paymentType", req.search.paymentType);
      params = params.append("channelPartner", req.search.channelPartner);
      params = params.append("applicationStatus", req.search.applicationStatus);
      params = params.append("loanPhase", req.search.loanPhase);
      params = params.append("agreementSigned", req.search.agreementSigned);
      params = params.append("searchValues", req.searchValues);
      if (req.sort) {
        params = params.append("sort", req.sort);
      }
      for (const key in req.query) {
        if (req.query[key] !== "" || req.query[key] !== 0) {
          params = params.append(key, req.query[key]);
        }
      }
    }
    return params;
  }

  getLoanApplication() {
    return this.loanApplication;
  }

  create(loan: LoanDetails): Observable<LoanDetails["loan"]> {
    const copy: LoanDetails = { ...loan };
    return this.http.post<LoanDetails["loan"]>(
      KREDILINE_SERVER_URL + this.resourceUrl,
      copy
    );
  }

  update(loan: LoanDetails): Observable<LoanDetails["loan"]> {
    const copy: LoanDetails = { ...loan };
    return this.http.put<LoanDetails["loan"]>(
      KREDILINE_SERVER_URL + this.resourceUrl,
      copy
    );
  }
  find(id: number): Observable<LoanDetails> {
    return this.http.get<LoanDetails>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getLoanDetails/${id}`
    );
  }

  bulkStatusUpdate(
    loanApplications: any[],
    currentStatus: number,
    newStatus: number
  ): Observable<any> {
    const data = {
      loanApplications: loanApplications,
      currentStatus: currentStatus,
      newStatus: newStatus,
    };
    return this.http.post(
      KREDILINE_SERVER_URL + this.resourceUrl + "/bulkStatusUpdate",
      JSON.stringify(data)
    );
  }

  loanApplicationsWithSubscriptionNumber(
    customerSubscriptionNumber: any
  ): Observable<LoanApplication[]> {
    return this.http.get<LoanApplication[]>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getLoanNoBySubId/` +
        customerSubscriptionNumber
    );
  }

  schedulePayment(
    loanApplication: number,
    amount1: any,
    date: any
  ): Observable<any> {
    date = this.dateUtils.convertLocalDateToServer(date);
    return this.http.get(
      KALEIDO_SERVER_API_URL +
        `api/backoffice/demand/processOpsInitiatedDemands`,
      {
        params: {
          applicationNumber: loanApplication,
          amount: amount1,
          repaymentDate: date,
        },
      }
    );
  }

  postFile(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(
      KALEIDO_SERVER_API_URL + "api/backoffice/upload/bulkLoanPaymentStatus",
      formData
    );
  }

  postFileToCreateLoan(fileToUpload: File): Observable<any> {
    const formData: FormData = new FormData();
    formData.append("file", fileToUpload, fileToUpload.name);
    return this.http.post(
      `${KREDILINE_SERVER_URL}api/backoffice/loans/applications/uploadNgoCustomerForLoan`,
      formData
    );
  }

  findLoanIfo(applicationNumber: number): Observable<LoanInfoDTO> {
    return this.http.get<LoanInfoDTO>(
      `${KREDILINE_SERVER_URL}api/backoffice/loans/applications/findLoanInfo`,
      {
        params: {
          loanApplicationId: applicationNumber,
        },
      }
    );
  }

  getLoanDemandViewDetail(id: number): Observable<LoanApplication> {
    return this.http.get<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/getRepaymentInfo/`,
      {
        params: {
          loanApplicationId: id,
        },
      }
    );
  }

  amendRepaymentDate(
    id: number,
    repaymentDate: Date,
    loanTenure: number
  ): Observable<LoanApplication> {
    let params: HttpParams = new HttpParams();
    params = params.append("loanApplicationId", id);
    params = params.append("repaymentDate", repaymentDate.toString());
    params = params.append("tenure", loanTenure);
    return this.http.get<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/amendRepaymentDateAndTenure`,
      {
        params,
      }
    );
  }

  validateFile(name: string) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "xls") {
      return true;
    } else if (ext.toLowerCase() == "csv") {
      return true;
    } else {
      return false;
    }
  }

  validateFileForNgoUpload(name: string) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() == "xls") {
      return true;
    } else if (ext.toLowerCase() == "xlsx") {
      return true;
    } else {
      return false;
    }
  }

  downloadLienRelease(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${
          KREDILINE_SERVER_URL + this.resourceUrl
        }/downloadLienReleaseLetter/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  downloadLienRequest(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${
          KREDILINE_SERVER_URL + this.resourceUrl
        }/downloadLienRequestDocument/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res: HttpResponse<Blob>) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  downloadLenderLetter(id: any): Observable<any> {
    const out = { data: null, filename: null };
    return this.http
      .get(
        `${KREDILINE_SERVER_URL + this.resourceUrl}/downloadLenderForm/${id}`,
        {
          observe: "response",
          responseType: "blob",
        }
      )
      .pipe(
        map((res: HttpResponse<Blob>) => {
          out.data = new Blob([res.body]);
          out.filename = res.headers.get("x-file-name");
          return out;
        })
      );
  }

  processPhysicalLien(id: number): Observable<LoanApplication> {
    return this.http.post<LoanApplication>(
      `${KREDILINE_SERVER_URL + this.resourceUrl}/physicalLienReceived/${id}`,
      ""
    );
  }
}
