<mat-form-field appearance="outline"  *ngIf="!selectFieldFormControl" [matTooltip]="toolTipLabel"
  [matTooltipDisabled]="!showToolTip"
  matTooltipPosition="above">
  <mat-label>{{ label }}</mat-label>
  <mat-select [(value)]="dataObj.value" [required]="isRequired" (selectionChange)="handelSelection()">
    <div class="select-search-block" *ngIf="showSearchBox">
      <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="searchFieldFormControl" />
        <mat-label class="search-placeholder">Search</mat-label>
        <mat-icon matSuffix class="icon">search</mat-icon>
      </mat-form-field>
    </div>
    <mat-option>None</mat-option>
    <mat-option *ngFor="let option of filteredValues" [value]="option">
      {{ option }}
    </mat-option>
  </mat-select>
</mat-form-field>
<!-- Template to use when formControl provided  -->
<mat-form-field *ngIf="selectFieldFormControl" appearance="outline" [matTooltip]="toolTipLabel"
  [matTooltipDisabled]="!showToolTip"
  matTooltipPosition="above">
  <mat-label>{{ label }}</mat-label>
  <mat-select [required]="isRequired" [formControl]="selectFieldFormControl">
    <div class="select-search-block" *ngIf="showSearchBox">
      <mat-form-field appearance="outline">
        <input matInput type="text" [formControl]="searchFieldFormControl" />
        <mat-label class="search-placeholder">Search</mat-label>
        <mat-icon matSuffix class="icon">search</mat-icon>
      </mat-form-field>
    </div>
    <mat-option>None</mat-option>
    <mat-option *ngFor="let option of filteredValues" [value]="option">
      {{ option }}
    </mat-option>
  </mat-select>
</mat-form-field>
