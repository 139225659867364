import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChange,
  SimpleChanges,
} from "@angular/core";
import { FormControl } from "@angular/forms";
import { set } from "lodash";
import { UiFields } from "src/app/constants/ui-config";
import { getProperty } from "src/app/utils/app.utils";

@Component({
  selector: "app-demand-schedule-detail",
  templateUrl: "./demand-schedule-detail.component.html",
  styleUrls: ["./demand-schedule-detail.component.scss"],
})
export class DemandScheduleDetailComponent implements OnInit, OnChanges {
  @Input() uiFieldsMap: Array<any> = [];
  @Input() demandSchedule: UiFields = {};
  @Input() enableEdit: boolean = false;

  @Output() showTenureError: EventEmitter<boolean> = new EventEmitter<boolean>();

  tenureFrequency: number = null;
  tenureFormControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.tenureFrequency = getProperty(
      this.demandSchedule,
      "tenure.value",
      null
    );
    this.tenureFormControl = new FormControl(this.tenureFrequency);
    this.tenureFormControl.valueChanges.subscribe((value: number) => {
      this.showTenureError.emit(value !== this.tenureFrequency);
      set(this.demandSchedule, "tenure.value", value);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    const demandScheduleChanges: SimpleChange = getProperty(
      changes,
      "demandSchedule",
      {}
    );
    if (
      demandScheduleChanges.currentValue !== demandScheduleChanges.previousValue
    ) {
      this.tenureFrequency = getProperty(
        this.demandSchedule,
        "tenure.value",
        null
      );
    }
  }
}
