<button *ngIf="authority.view" mat-button [matMenuTriggerFor]="belowMenu">
  Download
  <span class="material-icons"> keyboard_arrow_down </span>
</button>
<mat-menu #belowMenu="matMenu" yPosition="below" xPosition="before">
  <div *ngFor="let menu of downloadMenus">
    <span mat-menu-item [value]="menu.value"
    (click)="openDialog(menu)"
    (keypress)="openDialog(menu)"
    >{{menu.viewValue}}</span>
  </div>
</mat-menu>
