import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs/operators";

@Component({
  selector: 'app-select-field',
  templateUrl: './select-field.component.html',
  styleUrls: ['./select-field.component.scss']
})
export class SelectFieldComponent implements OnInit {
  @Input() dataObj: any = {};
  @Input() label: string = "";
  @Input() dropDownValues: Array<string> = [];
  @Input() isRequired: boolean = false;
  @Input() showSearchBox: boolean = false;
  @Input() selectFieldFormControl: FormControl | null = null;
  @Input() showToolTip: boolean = false;
  @Input() toolTipLabel: string = null;
  @Input() disbableDefault: boolean = false;

  @Output() emitSelection: EventEmitter<string> = new EventEmitter<string>();

  filteredValues: Array<string> = [];
  searchFieldFormControl: FormControl = new FormControl();

  ngOnInit(): void {
    this.emitSelection.emit(this.dataObj?.value);
    this.filteredValues = [...this.dropDownValues];
    this.searchFieldFormControl.valueChanges
      .pipe(debounceTime(300))
      .subscribe((value) => {
        this.filteredValues = this.dropDownValues.filter((reason) => {
          return reason.toLowerCase().includes(value.toLowerCase());
        });
      });
  }

  handelSelection(): void {
    this.emitSelection.emit(this.dataObj?.value);
  }
}
