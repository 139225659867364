<div class="dialog-container">
  <div mat-dialog-title class="dialog-title">
    <mat-icon class="closeicon" (click)="proceed()" (keypress)="proceed()" tabindex="0">close</mat-icon>
  </div>
  <div mat-dialog-content class="dialog-content-text">
    <img
      *ngIf="!data.isErrorDisplay"
      src="assets/images/common/upload-success.svg"
      alt="Success"
    />
    <img *ngIf="data.isErrorDisplay" src="assets/images/common/warning.svg" alt="Error" />
    <p>
      {{ data.description }}
    </p>
  </div>
  <div mat-dialog-actions>
    <div class="action-buttons">
      <button mat-button class="button4 cancel" (click)="proceed()">
        Okay
      </button>
    </div>
  </div>
</div>
