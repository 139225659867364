<br>
<br>
<div class="container">
    <div class="row justify-content-center align-items-center">
        <div class="col-md-4">
            <div class="text-center">
                <label class="k-primary-color-2 k-font-4" style="width: 100%" htmlFor="label-1">Forgot password</label>
                <label class="mat-subheader k-third-color-3 k-font-5" htmlFor="label-2">Don't worry we have got your back</label>
            </div>
                <div>&nbsp;</div>
                <div>
                    <form [formGroup]="resetForm" (ngSubmit)="requestReset()" novalidate class="clearfix">
                        <mat-form-field class="customholder" style="margin-bottom: 0">
                            <label class="country-label" htmlFor="label-3">+91</label>
                        <input matInput type="text" name="username" id="username" placeholder="Mobile number"
                            formControlName="username" [errorStateMatcher]="matcher" required autofocus >
                        <mat-hint> You will receive a reset link on this number</mat-hint>

                        <mat-error *ngIf="resetForm.get('username').hasError('required')">
                            Please enter your mobile number
                        </mat-error>
                        <mat-error *ngIf="resetForm.get('username').hasError('pattern')">
                           Your mobile number is invalid
                        </mat-error>
                        <mat-error *ngIf=" !resetForm.get('username').hasError('pattern') && (resetForm.get('username').hasError('minlength') || resetForm.get('username').hasError('maxlength'))">
                            Please enter valid 10 digit number
                        </mat-error>
                    </mat-form-field>
                        <div class="d-flex">
                            <div class="ml-auto">
                                <button mat-raised-button style="width: 152px" class="custom-button sign-in-button w-100" type="submit" [disabled]="resetForm.invalid">
                                    <span>SEND RESET LINK</span>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
        </div>
    </div>
</div>