import { Component } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { JhiAlertService, JhiLanguageService } from "ng-jhipster";

@Component({
  selector: "jhi-loan-review-dialog",
  templateUrl: "./kcredit-confirmation-accept-dialog.html",
})
export class LoanApplicationReviewAcceptDialogComponent{
  errorText: any = [];
  errorStatus: string;
  applicationStatus: string;
  noOfEntries: number;
  constructor(
    private jhiLanguageService: JhiLanguageService,
    public activeModal: NgbActiveModal,
    private alertService: JhiAlertService
  ) {
    // Look out - need to find an alternative
    // this.jhiLanguageService.addLocation("customer");
  }


  cancel() {
    this.activeModal.close("cancel");
  }

  approve() {
    this.activeModal.close("confirm");
  }

  

  private onNotifyError(error) {
    try {
      error = error.json();
    } catch (exception) {
      error.message = error.text();
    }
    this.onError(error);
  }

  private onError(error) {
    this.alertService.error(error.error);
  }
}
