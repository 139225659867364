import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-date-field',
  templateUrl: './date-field.component.html'
})
export class DateFieldComponent implements OnInit {
  @Input() dataObj: any = {};
  @Input() editable: boolean;
  @Input() label : string = "";
  @Input() isRequired: boolean = false;
  @Input() showToolTip: boolean = false;
  @Input() toolTipLabel: string = null;
  minDate: Date;

  constructor( private datePipe: DatePipe ) { }

  ngOnInit(): void {
    this.minDate = new Date(1930, 1, 1);
  }

  edit(date: any = {}, key: string = "", dataObj: any = {}): void {
    if (date["value"]) {
      const newDate: Date = new Date(date["value"]);
      const dateString = this.datePipe.transform(newDate, "yyyy-MM-dd");
      dataObj[key] = dateString;
    }
  }

  getDate(dataObj: any = {}): Date | null{
    if (dataObj.value)
     {
       const date = new Date(dataObj.value);
       return date       
     }
    return null;
  }


}
