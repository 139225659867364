<div class="sub-section-container">
    <div class="title-with-btns">
      <div class="business-review-subtitle-text">
        {{ title }}
      </div>
      <app-business-section-action-buttons
        [isEditing]="isEditing"
        (onRejectHandler)="onReject()"
        (onEditHandler)="onEdit()"
        (onCancelHandler)="onCancel()"
        (onSaveHandler)="onSave()"
      ></app-business-section-action-buttons>
    </div>
    <div class="details-grid">
      <div class="field-item" *ngFor="let field of fields">
        <span class="business-review-label">{{ field.label }}</span>
        <ng-container *ngIf="isEditing">
          <input type="text" [(ngModel)]="data[field.value]" />
        </ng-container>
        <ng-container *ngIf="!isEditing">
          <strong class="business-review-value">
            {{ data[field.value] || "--" }}
          </strong>
        </ng-container>
      </div>
    </div>
  </div>