import { Component, Inject, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { getProperty } from 'src/app/utils/app.utils';

@Component({
  selector: 'app-login-success-snackbar',
  templateUrl: './login-success-snackbar.component.html',
  styleUrls: ['./login-success-snackbar.component.scss']
})
export class LoginSuccessSnackbarComponent implements OnInit {
  message: string = null;
  constructor(@Inject(MAT_SNACK_BAR_DATA) private readonly data) { }

  ngOnInit(): void {
    this.message = getProperty(this.data, "message", null);
  }

}
