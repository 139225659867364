<div class="modal-body">
  <div display-flax justify-content-center align-items-center>
    <div class="display-flax justify-content-center align-items-center">
      <img
        src="assets/images/kaleidofin_logo.png"
        height="60"
        style="transform: scale(1.5)"
        alt ="Logo of kaleidofin"
      />
    </div>
    <div
      *ngIf="status == 'InProgress'"
      class="display-flax container justify-content-center align-items-center"
      style="position: relative; margin-top: -20px"
    >
      <div class="v1"></div>
      <div class="animation"></div>
    </div>
    <div
      *ngIf="status == 'Rejected' || status == 'Pending'"
      class="display-flax container justify-content-center align-items-center"
      style="position: relative; margin-top: -20px"
    >
      <img
        src="assets/images/bank-validation-2.png"
        style="position: absolute"
        height="10px"
        width="10px"
        alt ="Bank validation"
      />
      <div class="v1"></div>
    </div>
    <div
      *ngIf="status == 'Success' || status == 'NameMismatch'"
      class="display-flax container justify-content-center align-items-center"
      style="position: relative; margin-top: -20px"
    >
      <img
        src="assets/images/bank-validation-3.jpg"
        style="position: absolute"
        height="12px"
        width="12px"
        alt ="Bank validation"
      />
      <div class="v1"></div>
    </div>
    <br />
    <div class="display-flax justify-content-center align-items-center">
      <img
        src="assets/images/bank-validaton-1.png"
        height="31px"
        width="31px"
        alt ="Bank validation"
      />
    </div>
    <p>&nbsp;</p>

    <div *ngIf="status == 'InProgress'">
      <label
        class="display-flax justify-content-center align-items-center k-font-4 k-primary-color-4"
        htmlFor="label-1"
        >Verifying your bank details...</label
      >
      <label
        class="display-flax justify-content-center align-items-center k-font-11 k-primary-color-4"
         htmlFor="label-2"
        >We do this by transferring &#8377;1 into</label
      >
      <label
        class="display-flax justify-content-center align-items-center k-font-11 k-primary-color-4"
         htmlFor="label-3"
      >
        your account</label
      >
      <br />
    </div>
    <div *ngIf="status != 'InProgress'">
      <p class="col-md-10 mx-auto text-center k-font-4 k-primary-color-4">
        {{ messageTitle }}
      </p>
      <p class="col-md-10 mx-auto text-center k-font-11 k-primary-color-4">
        {{ messageDescription1 }}
      </p>
      <div *ngIf="status == 'NameMismatch'">
        <p class="col-md-10 mx-auto text-center k-font-3 k-primary-color-6">
          {{ messageDescription2 }}
        </p>
        <div class="display-flax flex-row justify-content-center">
          There is a name mismatch with the given account holder name
          <b>{{ accountHolderNameList[0] }}</b> and the zipnach validated name
          is <b>{{ accountHolderNameList[1] }}</b>
        </div>
      </div>
      <div class="display-flax" style="justify-content: space-evenly">
        <button
          *ngIf="status == 'Rejected' && resetCountbtn"
          class="btn btn-success"
          (click)="resetAndRetry()"
        >
          <span> RESET COUNT AND RETRY </span>
        </button>
        <button
          *ngIf="status == 'Rejected' || status == 'NameMismatch'"
          class="btn btn-danger"
          (click)="close()"
        >
          <span> CLOSE </span>
        </button>
        <button
          *ngIf="
            status == 'Success' ||
            status == 'Pending' ||
            status == 'NameMismatch'
          "
          class="btn btn-success"
          (click)="finish()"
        >
          <span> OK PROCEED </span>
        </button>
      </div>
    </div>
  </div>
</div>
